import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { OrderData } from "../../interfaces/orders";

function createData(
  column1: string,
  column2: string,
  column3: string,
  column4: string,
  modifiers?: any
) {
  return { column1, column2, column3, column4, modifiers };
}

export default function Product({ data }: { data: OrderData }) {
  console.log(data, "this is data");

  const productsMap = data.orderItems?.map(
    ({ name, quantity, price, total, modifiers }) =>
      createData(name, quantity, price, total, modifiers)
  );
  const rows = [
    ...productsMap,
    createData("", "", "Sub Total", data.subTotal),
    createData("", "", "Order Fees", data.orderFees),
    createData("", "", "Sales Tax", data.salesTax),
    createData("", "", "Tip", data.tip),
    createData("", "", "Total", data.total),
  ];

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "none",
          maxHeight: "calc(100vh - 200px)",
        }}
        className="tavleV1 customized"
      >
        <Table
          sx={{ minWidth: 350 }}
          stickyHeader
          aria-label="sticky table"
          className=""
        >
          <TableHead sx={{ background: "#CD4D4B" }}>
            <TableRow>
              <TableCell
                sx={{
                  paddingY: "8px",
                  color: "white",
                  border: "none",
                  width: "30%",
                  backgroundColor: "transparent",
                }}
              >
                Product
              </TableCell>
              <TableCell
                sx={{
                  paddingY: "8px",
                  color: "white",
                  border: "none",
                  width: "30%",
                  backgroundColor: "transparent",
                }}
                align="center"
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  paddingY: "8px",
                  color: "white",
                  border: "none",
                  width: "30%",
                  backgroundColor: "transparent",
                }}
                align="center"
              >
                Unit Price
              </TableCell>
              <TableCell
                sx={{
                  paddingY: "8px",
                  width: "70%",
                  minWidth: "200px",
                  color: "white",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                align="center"
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "> tr:nth-last-child(-n + 5):not(:last-child) > td:nth-child(-n + 2)":
                {
                  borderBottomColor: "transparent",
                },
              "> tr:nth-last-child(-n + 5) > td:nth-child(2)": {
                borderLeftColor: "transparent !important",
              },

              "> tr > td:first-child": {
                borderLeft: "1px solid #E5E5E5",
              },

              "> tr > td:last-child": {
                borderRight: "1px solid #E5E5E5",
              },
            }}
          >
            {rows?.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  sx={{
                    "td, th": {
                      borderBottom: "1px solid rgba(237, 238, 241, 1)",
                    },
                  }}
                >
                  <TableCell
                    component="td"
                    scope="row"
                    sx={{
                      py: "11px",
                      fontSize: "12px",
                      color: "rgba(59, 65, 85, 1)",
                      width: "40%",
                    }}
                  >
                    {row.column1}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: "11px",
                      fontSize: "12px",
                      color: "rgba(59, 65, 85, 1)",
                      width: "30%",
                    }}
                  >
                    {row.column2}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: "11px",
                      fontSize: "12px",
                      color: "rgba(59, 65, 85, 1)",
                      width: "30%",
                    }}
                  >
                    {row.column3}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: "11px",
                      fontSize: "12px",
                      color: "rgba(59, 65, 85, 1)",
                      width: "30%",
                    }}
                  >
                    {row.column4}
                  </TableCell>
                </TableRow>
                {row.modifiers?.length > 0 &&
                  row.modifiers.map((item: any, i: number) => (
                    <TableRow key={`${index}-${i}`}>
                      <TableCell
                        component="td"
                        scope="row"
                        sx={{
                          py: "5px",
                          fontSize: "10px",
                          color: "rgba(100, 100, 100, 1)",
                          paddingLeft: "20px",
                        }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          py: "5px",
                          fontSize: "10px",
                          color: "rgba(100, 100, 100, 1)",
                        }}
                      >
                        {item?.quantity}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          py: "5px",
                          fontSize: "10px",
                          color: "rgba(100, 100, 100, 1)",
                        }}
                      >
                        {item?.price}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          py: "5px",
                          fontSize: "10px",
                          color: "rgba(100, 100, 100, 1)",
                        }}
                      >
                        {item?.price * item?.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
