import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import "./Dashboard.scss";
import BasicCard from "../../Components/Card/Card";
import VerticalCard from "../../Components/VerticalCard/VerticalCard";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  handleDashboard,
  handleRefreshDashboard,
} from "../../redux/actions/dashboardAction";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  setCustomFilterData,
  setFilterData,
} from "../../redux/features/dashboard";
import { LoadingComponent } from "../../Components/Loader/Loader";
import { LineChartComponent } from "../../Components/Chart/LineChart";
import {
  setGraphFilter,
  setSortOrderFilter,
  setTimeFrameData,
} from "../../redux/features/filter";
import { RestaurantFilters } from "../../Components/RestaurantFilters";
import { graph } from "../../interfaces";
import { OrderFilters } from "../../Components/OrderFilters";
import {
  BarGraphIcon,
  ChartIcon,
  CoinDollarOutline,
  DeviceOutlineComputer,
  MobileOutline,
  TruckOutline,
} from "../../assets/icons/iconComponents";
import { AppOrdersModel } from "../../Components/Dashboard/AppOrdersModel";
import { handleGetRestaurantList } from "../../redux/actions/customerInsightsAction";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  //redux state's
  const dashboardData: any = useAppSelector((state) => state.dashboard.data);
  const graphData: graph = useAppSelector((state) => state.dashboard.graphData);
  const restaurantsData = useAppSelector(
    (state) => state.dashboard.restaurantData
  );
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const filterData = useAppSelector((state) => state.dashboard.filter);
  const isLoading = useAppSelector((state) => state.auth.loading);
  const timeFrame = useAppSelector((state) => state.filter.timeFrame);
  const sortValue = useAppSelector((state) => state.filter.sortOrder);
  const graphFilterValue = useAppSelector((state) => state.filter.graphFilter);
  const customFilterData = useAppSelector(
    (state) => state.dashboard.customFilterData
  );

  const [open, setOpen] = useState(false);
  const [appModalOpen, setAppModalOpen] = useState(false);

  const bodyElt = document.querySelector("body");
  if (bodyElt) {
    bodyElt.style.background = "#F4F4F5";
  }

  useEffect(() => {
    if (
      customFilterData?.current &&
      customFilterData?.previous &&
      filterData == "customFilter"
    ) {
      handleDashboard(
        dispatch,
        filterData,
        timeFrame,
        sortValue,
        graphFilterValue,
        selectedRestaurant,
        customFilterData
      );
    } else {
      handleDashboard(
        dispatch,
        filterData,
        timeFrame,
        sortValue,
        graphFilterValue,
        selectedRestaurant
      );
    }
  }, [
    filterData,
    graphFilterValue,
    timeFrame,
    sortValue,
    customFilterData,
    selectedRestaurant,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleDashboard(
        dispatch,
        filterData,
        timeFrame,
        sortValue,
        graphFilterValue,
        selectedRestaurant
      );
    }, 3600000); // 1 hour in milliseconds

    return () => clearInterval(interval);
  }, [filterData, graphFilterValue, timeFrame, sortValue]);

  const handleFilters = (
    event: React.MouseEvent<HTMLElement>,
    data: string
  ) => {
    if (data == "customFilter") {
      setOpen(true);
      dispatch(setFilterData(data));
    } else {
      setOpen(false);

      dispatch(setFilterData(data));
    }
  };

  const handleGraphFilter = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    dispatch(setGraphFilter(value));
  };

  const handleRestaurantFilter = (value: string) => {
    dispatch(setSortOrderFilter(value));
  };

  const handleRestaurantTime = (value: string) => {
    dispatch(setTimeFrameData(value));
  };
  const refreshData = () => {
    handleRefreshDashboard(
      dispatch,
      filterData,
      timeFrame,
      sortValue,
      graphFilterValue,
      selectedRestaurant
    );
  };
  const handleModalClick = () => {
    setAppModalOpen(true);
  };
  const handleDropdownChange = (event: any) => {
    setSelectedRestaurant(event.target.value as string);
  };
  useEffect(() => {
    handleGetRestaurantList(dispatch);
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "calc(100vh - 65px)",
        overflow: "auto",
        p: 3,
      }}
    >
      {dashboardData !== undefined ? (
        <>
          <Box>
            <Box>
              <OrderFilters
                filterData={filterData}
                handleFilters={handleFilters}
                refreshData={refreshData}
                open={open}
                setOpen={setOpen}
                CustomDispatchFunction={setCustomFilterData}
                selectedRestaurant={selectedRestaurant}
                handleDropdownChange={handleDropdownChange}
                isRestaurantFilter={true}
              />
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.orders}
                      isAverage={false}
                      type={""}
                      title="Orders"
                      subtitle="Before Taxes & Fees"
                      isWhole={true}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.revenue}
                      isAverage={false}
                      type={"$"}
                      title="Revenue"
                      subtitle="Before Taxes & Fees"
                      ownLogo={true}
                      logo={<CoinDollarOutline />}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.averageOrderAmount}
                      isAverage={false}
                      type={"$"}
                      title="Average Order Amount"
                      subtitle="Before Taxes & Fees"
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.totalFees}
                      isAverage={false}
                      type={"$"}
                      title="Total Fees Collected"
                      ownLogo={true}
                      logo={<CoinDollarOutline />}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.pickUpFees}
                      isAverage={true}
                      type={"$"}
                      title="Pick Up Fees"
                      ownLogo={true}
                      logo={<CoinDollarOutline />}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.deliveryFees}
                      isAverage={true}
                      type={"$"}
                      title="Delivery Fees"
                      ownLogo={true}
                      logo={<CoinDollarOutline />}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.deliveryOrders}
                      isAverage={false}
                      type={"%"}
                      title="Delivery Orders"
                      ownLogo={true}
                      logo={<TruckOutline />}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <span onClick={handleModalClick}>
                      {" "}
                      <BasicCard
                        data={dashboardData?.appOrders}
                        isAverage={false}
                        type={""}
                        title="App Orders"
                        isWhole={true}
                        ownLogo={true}
                        logo={<MobileOutline />}
                      />
                    </span>
                  </Grid>
                  <Grid item xl={3} lg={4} xs={6} className="grid-child-v1">
                    <BasicCard
                      data={dashboardData?.webOrders}
                      isAverage={false}
                      type={""}
                      title="Web  Orders"
                      isWhole={true}
                      ownLogo={true}
                      logo={<DeviceOutlineComputer />}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                className="cardV2"
                sx={{ maxWidth: "calc(100vw - 220px)", overflow: "auto" }}
                mt={3}
                p={2}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <ToggleButtonGroup
                    className="tbgv1 customzied"
                    color="warning"
                    size="small"
                    value={graphFilterValue}
                    exclusive
                    onChange={handleGraphFilter}
                    aria-label="Platform"
                  >
                    <ToggleButton value="orders">Orders</ToggleButton>
                    <ToggleButton value="revenue">Revenue</ToggleButton>
                    <ToggleButton value="feesCollected">
                      Fees Collected
                    </ToggleButton>
                    <ToggleButton value="projectedRevenue">
                      Projected Revenue
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {/* these below button are static and not working you need to make them dynamic  */}
                  {/* <Box sx={{ display: "flex", gap: "1rem" }}>
                    <button className="circleBtnV1">
                      <ChartIcon />
                    </button>

                    <button className="circleBtnV1 selected">
                      <BarGraphIcon />
                    </button>
                  </Box> */}
                </Box>
                <Box
                  mt={1}
                  sx={{
                    boxShadow:
                      "0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)",
                    textAlign: "center",
                    background: "#FFF",
                    color: "#0A142E",
                    borderRadius: 1,

                    p: 1.5,
                  }}
                >
                  <LineChartComponent
                    current={graphData?.current ?? graphData?.dataPoints}
                    previous={graphData?.previous ?? graphData?.y_pred}
                    projected={
                      graphData?.dataPoints && graphData?.y_pred ? true : false
                    }
                    dateFilter={graphData?.dateFilter}
                    FilterUnit={graphData?.FilterUnit}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              maxWidth: "calc(100vw - 220px)",
              overflow: "auto",
              background: "white",
              boxShadow: "0px 4px 24px 0px rgba(188, 188, 188, 0.15)",
              borderRadius: "1rem",
              marginTop: "1.5rem",
              padding: "1.5rem",
            }}
          >
            <RestaurantFilters
              sortValue={sortValue}
              timeFrame={timeFrame}
              handleRestaurantTime={handleRestaurantTime}
              handleRestaurantFilter={handleRestaurantFilter}
            />
            <AppOrdersModel
              open={appModalOpen}
              setOpen={setAppModalOpen}
              filterData={filterData}
              customFilterData={customFilterData}
              isLoading={isLoading}
              name={"App Orders Listing"}
            />
            <VerticalCard
              restaurantsData={restaurantsData}
              isLoading={isLoading}
              rowsPerPage={30}
            />
          </Box>
        </>
      ) : (
        <LoadingComponent />
      )}
    </Box>
  );
}
