import { headersConfiguration } from "./headersHelper";
import { fetchFunction } from "./asyncFunction";

export const POST = async (url: string, data = {}, fileUpload?: boolean) =>
  new Promise((resolve, reject) => {
    const options: any = headersConfiguration();
    fetchFunction(url, "POST", options, resolve, reject, data);
  });

export const GET = (url: any) =>
  new Promise((resolve, reject) => {
    const options = headersConfiguration();
    fetchFunction(url, "GET", options, resolve, reject);
  });

export const PUT = (url: string, data = {}) =>
  new Promise((resolve, reject) => {
    const options = headersConfiguration();
    fetchFunction(url, "PUT", options, resolve, reject, data);
  });

export const DELETE = (url: string, data = {}) =>
  new Promise((resolve, reject) => {
    const options = headersConfiguration();
    fetchFunction(url, "DELETE", options, resolve, reject, data);
  });
