import { POST } from "../../services/common/http";
import { BASE_URL } from "../../services/common/const";
import {
  setAppOrdersData,
  setDashboardData,
  setGraphData,
  setRestaurantData,
} from "../features/dashboard";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../features/auth";

export const handleDashboard = async (
  dispatch: Dispatch<AnyAction>,
  filterValue: string,
  timeFrameValue: string,
  sortValue: string,
  graphFilterValue: string,
  selectedRestaurant: string,
  customFilterData?: any // Ensure customFilterData matches the type
) => {
  dispatch(setLoading(true));
  try {
    let data: any = {
      dateFilter: filterValue,
      dateFilterRestaurant: timeFrameValue,
      sortOrder: sortValue,
      graphFilter: graphFilterValue,
      restaurant: selectedRestaurant,
    };
    if (customFilterData && filterValue == "customFilter") {
      data = {
        dateFilter: filterValue,
        dateFilterRestaurant: timeFrameValue,
        sortOrder: sortValue,
        graphFilter: graphFilterValue,
        previous: customFilterData.previous,
        current: customFilterData.current,
        restaurant: selectedRestaurant,
      };
    }
    const response: any = await POST(`${BASE_URL}/dashboard/data`, data);
    if (response.data !== null) {
      dispatch(setDashboardData(response.data));
      dispatch(setGraphData(response.data.graphData));
      dispatch(setRestaurantData(response.data.topRestaurantsData));
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleDashboard", error);
    dispatch(setLoading(false));
  }
};

export const handleRefreshDashboard = async (
  dispatch: Dispatch<AnyAction>,
  filterValue: string,
  timeFrameValue: string,
  sortValue: string,
  graphFilterValue: string,
  selectedRestaurant: string
) => {
  dispatch(setLoading(true));
  try {
    const response: any = await POST(`${BASE_URL}/dashboard/refresh`, {
      dateFilter: filterValue,
    });

    handleDashboard(
      dispatch,
      filterValue,
      timeFrameValue,
      sortValue,
      graphFilterValue,
      selectedRestaurant
    );

    dispatch(setLoading(false));
  } catch (error) {
    console.log("err in handleDashboard", error);
    dispatch(setLoading(false));
  }
};

export const handleAppOrderListingDashboard = async (
  dispatch: Dispatch<AnyAction>,
  filterValue: string,
  customFilterData?: any // Ensure customFilterData matches the type
) => {
  dispatch(setLoading(true));
  try {
    let data: any = {
      dateFilter: filterValue,
    };
    if (customFilterData && filterValue == "customFilter") {
      data = {
        dateFilter: filterValue,
        previous: customFilterData.previous,
        current: customFilterData.current,
      };
    }
    const response: any = await POST(`${BASE_URL}/dashboard/app-orders`, data);
    if (response.data !== null) {
      dispatch(setAppOrdersData(response.data?.orders));
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleDashboard", error);
    dispatch(setLoading(false));
  }
};
