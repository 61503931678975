import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import PreLogin from "./PreLogin";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux/store";
import { verifyUser } from "../redux/actions/authAction";
import UserRoutes from "./UserRoutes";
import { LoadingComponent } from "../Components/Loader/Loader";
import { PreLoginLoader } from "../Components/Loader/PreLoginLoader";

export default function RootNavigation() {
  const dispatch = useDispatch();
  //redux state
  const token = useAppSelector((state) => state.auth.jwtToken);

  useEffect(() => {
    const verifyAndNavigate = async () => {
      if (token) {
        await verifyUser(dispatch);
      }
    };

    verifyAndNavigate();
  }, [token]);
  const FallbackLoadingComponent = () => <LoadingComponent />;
  const PreLoginFallbackLoadingComponent = () => <PreLoginLoader />;

  return (
    <Suspense fallback={<PreLoginFallbackLoadingComponent />}>
      <Routes>
        <Route element={<PreLogin />}>
          <Route element={<SignIn />} path="/" />
        </Route>

        <Route
          path="/*"
          element={
            <Suspense fallback={<FallbackLoadingComponent />}>
              <UserRoutes />
            </Suspense>
          }
        />
      </Routes>
    </Suspense>
  );
}
