import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: "allTime",
  mapFilter: "all",
  orderReferenceList: [
    {
      _id: "",
      name: "",
      phone_number: "",
      restaurant: "",
      order_id: "",
      order_time: "",
      total: "",
    },
  ],
  customFilterData: {
    current: new Date(), // Provide the initial value you want here
    previous: new Date(), // Provide the initial value you want here
  },
  colorMap: {
    "32301": "#e6194b",
    "32303": "#9a6324",
    "32304": "#e6beff",
    "32305": "#008080",
    "32308": "#911eb4",
    "32309": "#ffe119",
    "32310": "#4363d8",
    "32311": "#f58231",
    "32312": "#bcf60c",
    "32317": "#46f0f0",
    "32399": "#3cb44b",
    "33327": "#fabebe",
    "34145": "#f032e6",
  },
  restaurantList: [],
  mapDetails: [],
  customerInsightData: {
    newCustomers: 0,
    occasionalCustomers: 0,
    frequentCustomers: 0,
    totalCustomers: 0,
    totalSales: 0,
    totalSalesNew: 0,
    totalSalesOccasional: 0,
    totalSalesFrequent: 0,
    percentageNew: 0,
    percentageOccasional: 0,
    percentageFrequent: 0,
    avgOrderValueNew: 0,
    avgOrderValueOccasional: 0,
    avgOrderValueFrequent: 0,
    frequentCustomerPercentage: 0,
    occasionalCustomerPercentage: 0,
    newCustomerPercentage: 0,
  },
};

export const customerInsightsSlice = createSlice({
  name: "insight",
  initialState,
  reducers: {
    setCustomerInsightData: (state, action) => {
      state.customerInsightData = action.payload;
    },
    setRestaurantList: (state, action) => {
      state.restaurantList = action.payload;
    },
    setCustomerInsightFilterData: (state, action) => {
      if (action.payload === null) {
        state.filter = "allTime";
      } else {
        state.filter = action.payload;
      }
    },

    setCustomerInsightCustomFilterData: (state, action) => {
      state.customFilterData = action.payload;
    },
    setMapDetailsData: (state, action) => {
      state.mapDetails = action.payload;
    },
    setMapFilter: (state, action) => {
      state.mapFilter = action.payload;
    },
    setColorMap: (state, action) => {
      state.colorMap = { ...state.colorMap, ...action.payload };
    },
    setOrderReferenceList: (state, action) => {
      state.orderReferenceList = action.payload;
    },
  },
});

export const {
  setCustomerInsightCustomFilterData,
  setOrderReferenceList,
  setCustomerInsightFilterData,
  setCustomerInsightData,
  setRestaurantList,
  setMapDetailsData,
  setMapFilter,
  setColorMap,
} = customerInsightsSlice.actions;
export default customerInsightsSlice.reducer;
