import { store } from "../../redux/store";

export const headersConfiguration = () => {
  let token = store.getState().auth.jwtToken;

  let options = {
    Authorization: token ? `Bearer ${token}` : "",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return options;
};
