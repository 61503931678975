import React from "react";
import { Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export default function OrderDetailsPlaceholders() {
  return (
    <Box>
      <Box
        sx={{
          borderBottom: "1px solid #EDEEF1",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography
            className="loads"
            sx={{ height: 20, borderRadius: 2, width: 100 }}
            mb={1}
          ></Typography>

          <Typography
            className="loads"
            sx={{ height: 20, borderRadius: 2, width: 100 }}
            mb={1}
          ></Typography>
        </Box>

        <Box mb={1}>
          <Typography
            className="loads"
            sx={{ width: 250, height: 34, borderRadius: 4 }}
          ></Typography>

          <Typography
            className="loads"
            sx={{ width: 250, height: 20, borderRadius: 4 }}
            my={1}
          ></Typography>

          <Typography
            className="loads"
            sx={{ width: 250, height: 20, borderRadius: 4 }}
          ></Typography>
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "none",
          maxHeight: " calc(100vh - 260px)",
          overflow: "auto",
        }}
      >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="td" scope="row">
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ py: "18px", width: "70%", minWidth: "200px" }}
              >
                <Typography
                  className="loads"
                  sx={{ width: 60, height: 20, borderRadius: 2 }}
                ></Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
