import { DELETE, GET, POST, PUT } from "../../services/common/http";
import { BASE_URL } from "../../services/common/const";

import { AnyAction, Dispatch, createAsyncThunk } from "@reduxjs/toolkit";
import toast, { Toaster } from "react-hot-toast";
import axios, { AxiosRequestConfig } from "axios";

import { handleApiError } from "../../services/common/commonFunctions";
import {
  setCampaignAdWatchedUsers,
  setCampaignAwardedUsers,
  setCampaignInsight,
  setCampaignList,
  setCampaignReports,
  setCampaignTotalPageCount,
} from "../features/campaign";
import { ICampaignFormData } from "../../interfaces/campaignInterface";
import { setLoading } from "../features/auth";

export const handleGetCampaignList = createAsyncThunk(
  "Campaign/fetchCampaignList",
  async (
    {
      searchTerm,
      totalPage,
      page,
      setLoading,
    }: {
      searchTerm: string;
      totalPage: number;
      page: number;
      setLoading: any;
    },
    { dispatch }
  ) => {
    setLoading(true);
    try {
      let response: any;
      let data = {
        search: searchTerm,
        pageSize: totalPage,
        page: page,
      };

      response = await POST(`${BASE_URL}/campaign/list`, data);

      if (response.data !== null) {
        let campaignData = response.data.campaignData || [];
        let totalPages = response.data.totalPages || 1;

        dispatch(setCampaignList(campaignData));
        dispatch(setCampaignTotalPageCount(totalPages));
        setLoading(false);
      } else if (response.error) {
        setLoading(false);
        console.log(response.error);
        alert("something went wrong");
        return;
      }
    } catch (error) {
      console.log("err in handleGetCampaignList", error);
      setLoading(false);
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);

export const handleGetCampaignListWithoutPagination = createAsyncThunk(
  "Campaign/fetchCampaignListWithoutPagination",
  async (_, { dispatch }) => {
    try {
      let response: any;

      response = await POST(`${BASE_URL}/campaign/list`);

      if (response.data !== null) {
        let CouponData = response.data.campaignData || [];
        let totalPages = response.data.totalPages || 1;

        dispatch(setCampaignList(CouponData));
        dispatch(setCampaignTotalPageCount(totalPages));
      } else if (response.error) {
        console.log(response.error);
        alert("something went wrong");
        return;
      }
    } catch (error) {
      console.log("err in handleGetCampaignList", error);
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);

export const handleDeleteCampaign = createAsyncThunk(
  "Campaign/Delete",
  async (
    {
      selectedId,
      setLoading,
      setCreate,
      created,
    }: {
      selectedId: string | undefined;
      setLoading: any;
      setCreate: any;
      created: boolean;
    },
    { dispatch }
  ) => {
    try {
      console.log("i am in here");

      setLoading(true);

      const response: any = await DELETE(`${BASE_URL}/campaign/${selectedId}`);

      setCreate(!created);
      setLoading(false);
    } catch (error) {
      console.log("err in handleGetCustomerDetails", error);
      setLoading(false);
    }
  }
);
export const handleCreateCampaignOption = createAsyncThunk(
  "Campaign/createCampaignOption",
  async (
    {
      data,
      setError,
      setOpen,
      setLoading,
      cb,
    }: {
      data: any;
      setError: any;
      setOpen: any;
      setLoading: any;
      cb: () => void;
    },
    { dispatch, getState }
  ) => {
    const id = toast.loading("Uploading...");
    const state: any = getState(); // Access the entire Redux state
    const jwtToken = state.auth.jwtToken;

    try {
      setLoading(true);

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      const config: AxiosRequestConfig = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
        },
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(`Upload Progress: ${progress}%`);
          // Update the toast message with the current upload progress
          if (id) toast.loading(`Uploading: ${progress}%`, { id: id });
        },
      };

      setOpen(false);

      const response = await axios.post(
        `${BASE_URL}/campaign/create`,
        formData,
        config
      );

      if (response.data !== null) {
        toast.success("Campaign created successfully!", { id: id });

        dispatch(handleGetCampaignListWithoutPagination());
        setLoading(false);
        setError("");
      }
      cb();
    } catch (error) {
      console.log("Error in handleCreateCampaignOption", error);
      setLoading(false);
      setOpen(false);
      const message = handleApiError(error); // Assuming handleApiError is defined somewhere
      toast.error(message, { id: id });
    }
  }
);

export const handleEditCampaignOption = createAsyncThunk(
  "Campaign/editCampaignOption",
  async (
    {
      campaignId,
      data,
      setError,
      setOpen,
      setLoading,
    }: {
      campaignId: any;

      data: any;
      setError: any;
      setOpen: any;
      setLoading: any;
    },
    { dispatch, getState }
  ) => {
    const id = toast.loading("Uploading...");
    const state: any = getState(); // Access the entire Redux state
    const jwtToken = state.auth.jwtToken;

    try {
      setLoading(true);
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      const config: AxiosRequestConfig = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
        },
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(`Upload Progress: ${progress}%`);
          // Update the toast message with the current upload progress
          if (id) toast.loading(`Uploading: ${progress}%`, { id: id });
        },
      };

      setOpen(false);
      const response = await axios.put(
        `${BASE_URL}/campaign/${campaignId}`,
        formData,
        config
      );
      if (response.data !== null) {
        toast.success("Campaign updated successfully!", { id: id });

        dispatch(handleGetCampaignListWithoutPagination());
        setLoading(false);
        setError("");
      }
    } catch (error) {
      console.log("err in handleEditCampaignOption", error);
      setLoading(false);
      const message = handleApiError(error);
      toast.error(message, { id: id });
    }
  }
);

export const handleCampaignInsight = createAsyncThunk(
  "Campaign/CampaignInsight",
  async (
    {
      campaignId,
      setError,
      dateFilter,
      setLoading,
    }: {
      campaignId: any;
      setError: any;
      dateFilter: string;
      setLoading: any;
    },
    { dispatch, getState }
  ) => {
    const state: any = getState(); // Access the entire Redux state
    const jwtToken = state.auth.jwtToken;

    try {
      setLoading(true);

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
        },
      };

      const response = await axios.post(
        `${BASE_URL}/campaign/insights/${campaignId}`,
        { dateFilter: dateFilter },
        config
      );
      if (response.data.data !== null) {
        console.log(response.data);

        dispatch(setCampaignInsight(response.data.data));
        setLoading(false);
        setError("");
      }
    } catch (error) {
      console.log("err in handleEditCampaignOption", error);
      setLoading(false);
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);
export const handleCampaignWatchedUsers = createAsyncThunk(
  "Campaign/CampaignInsight",
  async (
    {
      campaignId,
      setError,
      dateFilter,
      setLoading,
    }: {
      campaignId: any;
      setError: any;
      dateFilter: string;
      setLoading: any;
    },
    { dispatch, getState }
  ) => {
    const state: any = getState(); // Access the entire Redux state
    const jwtToken = state.auth.jwtToken;

    try {
      setLoading(true);

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: jwtToken ? `Bearer ${jwtToken}` : "",
        },
      };

      const response = await axios.post(
        `${BASE_URL}/campaign/watched-users/${campaignId}`,
        { dateFilter: dateFilter },
        config
      );
      if (response.data.data !== null) {
        console.log(response.data);

        dispatch(setCampaignAdWatchedUsers(response.data.data));
        setLoading(false);
        setError("");
      }
    } catch (error) {
      console.log("err in handleEditCampaignOption", error);
      setLoading(false);
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);
export const handleGetCampaignReport = createAsyncThunk(
  "Campaign/fetchCampaignReport",
  async ({ id }: { id: any }, { dispatch }) => {
    try {
      let response: any;
      dispatch(setLoading(true));
      response = await GET(`${BASE_URL}/campaign/campaign-reports/${id}`);

      if (response.data !== null) {
        dispatch(setCampaignReports(response.data));
        dispatch(setLoading(false));
      } else if (response.error) {
        console.log(response.error);
        alert("something went wrong");
        dispatch(setLoading(false));

        return;
      }
    } catch (error) {
      console.log("err in handleGetCampaignReport", error);
      const message = handleApiError(error);
      setLoading(false);

      toast.error(message);
    }
  }
);

export const handleGetCampaignAwardedUsersList = createAsyncThunk(
  "promotionalCampaign/CoinAwardedUsers",
  async (
    {
      campaignId,
      setTotalPage,
      totalPage,
      page,
    }: {
      campaignId: string | undefined;
      setTotalPage: any;
      totalPage: number;
      page: number;
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    try {
      let response: any;
      let data = {
        limit: totalPage,
        page: page,
        id: campaignId,
      };

      response = await POST(`${BASE_URL}/campaign/awarded-users`, data);

      if (response.data !== null) {
        let awardedUsers = response.data.awardedUsers || [];
        let totalPages = response.data.totalPages || 1;

        dispatch(setCampaignAwardedUsers(awardedUsers));
        setTotalPage(totalPages);
        dispatch(setLoading(false));
      } else if (response.error) {
        dispatch(setLoading(false));
        console.log(response.error);
        alert("something went wrong");
        return;
      }
    } catch (error) {
      console.log("err in handleGetPromotionalWheelList", error);
      dispatch(setLoading(false));
      const message = handleApiError(error);
      toast.error(message);
    }
  }
);
