import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaignList: [
    {
      _id: "",
      campaignName: "",
      advertiserId: "",
      from: "",
      to: "",
      createdAt: "",
      updatedAt: "",
    },
  ],
  campaignReport: {
    totalViews: 0,
    partialAdView: 0,
    totalWatchedViews: 0,
    campaignName: "",
    totalCoins: 0,
    totalCoinsMonth: 0,
    totalCoinsToday: 0,
  },
  awardedUsers: [
    {
      userId: "",
      name: "",
      awardedDate: "",
      email: "",
      awardedCoin: 0,
    },
  ],

  campaignInsight: {
    PartialWatchViewCount: 0,
    videoWatchedCount: 0,
    videoClickCount: 0,
    adType: "",
    adViewGraphData: {
      current: [{ x: "", y: 0 }],
      previous: [{ x: "", y: 0 }],
    },
    adClickGraphData: {
      current: [{ x: "", y: 0 }],
      previous: [{ x: "", y: 0 }],
    },
    videoWatchedGraphData: {
      current: [{ x: "", y: 0 }],
      previous: [{ x: "", y: 0 }],
    },
    partialWatchedGraphData: {
      current: [{ x: "", y: 0 }],
      previous: [{ x: "", y: 0 }],
    },

    adViewCount: 0,
    adClickCount: 0,
    dateFilter: "24hours",
    FilterUnit: "hour",
  },
  graphFilter: "viewed",
  filterValue: "24hours",
  TotalPageCount: 1,
  watchedUsers: [
    {
      _id: "",
      name: "",
      email: "",
      watchedAt: "",
    },
  ],
};

export const CampaignSlice = createSlice({
  name: "Campaign",
  initialState,
  reducers: {
    setCampaignList: (state, action) => {
      state.campaignList = action.payload;
    },
    setCampaignTotalPageCount: (state, action) => {
      state.TotalPageCount = action.payload;
    },
    setCampaignInsight: (state, action) => {
      state.campaignInsight = action.payload;
    },
    setCampaignAdWatchedUsers: (state, action) => {
      state.watchedUsers = action.payload;
    },
    setCampaignGraphFilter: (state, action) => {
      if (action.payload === null) {
        state.graphFilter = "orders";
      } else {
        state.graphFilter = action.payload;
      }
    },
    setCampaignFilterValue: (state, action) => {
      if (action.payload === null) {
        state.filterValue = "24hours";
      } else {
        state.filterValue = action.payload;
      }
    },
    setCampaignReports: (state, action) => {
      state.campaignReport = action.payload;
    },
    setCampaignAwardedUsers: (state, action) => {
      state.awardedUsers = action.payload;
    },
  },
});

export const {
  setCampaignList,
  setCampaignTotalPageCount,
  setCampaignInsight,
  setCampaignGraphFilter,
  setCampaignFilterValue,
  setCampaignAdWatchedUsers,
  setCampaignReports,
  setCampaignAwardedUsers,
} = CampaignSlice.actions;
export default CampaignSlice.reducer;
