import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wheelList: [
    {
      _id: "",
      name: "",
      categoryId: "",
      couponName: "",
      type: "",
      winPercentage: 0,
      timesAwarded: 0,
      points: 0,
      createdAt: "",
      updatedAt: "",
      categoryName: "",
    },
  ],
  categoryList: [{ coupon_category: "", _id: "" }],
  TotalPageCount: 1,
};

export const PromotionalWheelsSlice = createSlice({
  name: "promotionalWheel",
  initialState,
  reducers: {
    setPromotionalWheelList: (state, action) => {
      state.wheelList = action.payload;
    },

    setPromotionalWheelTotalPageCount: (state, action) => {
      state.TotalPageCount = action.payload;
    },
    setPromotionalWheelCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
  },
});

export const {
  setPromotionalWheelList,
  setPromotionalWheelTotalPageCount,
  setPromotionalWheelCategoryList,
} = PromotionalWheelsSlice.actions;
export default PromotionalWheelsSlice.reducer;
