import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  UserData: {},
  jwtToken: "",
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.UserData = action.payload;
    },
    setToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setUserData, setToken, setLoading } = authSlice.actions;
export default authSlice.reducer;
