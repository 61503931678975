import { POST,GET } from "./common/http";
import { BASE_URL } from "./common/const";
import { LoginData } from "../interfaces";

export const Login = (data: LoginData) =>
  POST(`${BASE_URL}/auth/authenticate`, data);

export const UpdateUserPassword = (id: any, data: any) =>
  POST(`${BASE_URL}/auth/update/` + id, data);

  export const VerifyJwt = () =>
  GET(`${BASE_URL}/auth/verifyjwt`);