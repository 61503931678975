import { Box, Button, Grid, Typography } from "@mui/material";
import BasicCard from "../Card/Card";
import { customerCalculationDataInterface } from "../../interfaces/customerDetails";
import moment from "moment-timezone";
import leftArrow from "../../assets/icons/iconsStatic/leftArrow.svg";
import { campaignCalculationDataInterface } from "../../interfaces/promotionalAdvertisement";

export const AdvertiserCalculation = ({
  goBackFunction,
  advertiserCalculationDetails,
}: {
  goBackFunction: any;
  advertiserCalculationDetails: campaignCalculationDataInterface;
}) => {
  const formatDateAndTime = (dateString: any, noChar?: boolean) => {
    const easternTime = moment.tz(dateString, "America/New_York");
    let character = noChar ? "," : "at";
    const formattedDateAndTime = easternTime.format(
      `MMM D, YYYY [${character}] h:mm A`
    );
    return formattedDateAndTime;
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: "1.5rem" }}>
        <button onClick={goBackFunction} className="btn-white customized">
          <img src={leftArrow} alt="" />
          <span>Back</span>
        </button>
        <Box
          sx={{
            background: "#00B976",
            padding: "13px 1.5rem",
            color: "white",
            fontSize: "14px",
            fontWeight: "500",
            borderRadius: "1.5rem",
            boxShadow: "0px 4px 24px 0px rgba(188, 188, 188, 0.15)",
          }}
        >
          {advertiserCalculationDetails.campaignName}
        </Box>
      </Box>

      <Box my={3}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <BasicCard
              conversionData={advertiserCalculationDetails?.totalViews}
              isConversion={true}
              isAverage={false}
              noArrow={true}
              type={""}
              title="Total Views On The Ad"
              isWhole={true}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <BasicCard
              conversionData={advertiserCalculationDetails?.totalWatchedViews}
              isConversion={true}
              isAverage={false}
              noArrow={true}
              type={""}
              title="Whole Ad Views"
              subtitle="Before Taxes & Fees"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <BasicCard
              conversionData={advertiserCalculationDetails?.partialAdView}
              isConversion={true}
              isAverage={false}
              noArrow={true}
              type={""}
              title="Partial Ad Views"
              subtitle="Before Taxes & Fees"
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <Box sx={{ height: "100%" }}>
              <BasicCard
                conversionData={advertiserCalculationDetails?.totalCoins || 0}
                isConversion={true}
                isAverage={false}
                noArrow={true}
                type={""}
                title="Total Coins Awarded"
              />
            </Box>
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <Box sx={{ height: "100%" }}>
              <BasicCard
                conversionData={
                  advertiserCalculationDetails?.totalCoinsToday || 0
                }
                isConversion={true}
                isAverage={false}
                noArrow={true}
                type={""}
                title=" Coins Awarded Today"
              />
            </Box>
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12}>
            <span>
              <BasicCard
                conversionData={
                  advertiserCalculationDetails?.totalCoinsMonth || 0
                }
                isConversion={true}
                isAverage={false}
                type={""}
                noArrow={true}
                title="Coins Awarded This month "
              />
            </span>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
