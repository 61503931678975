import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "react-redux";
import { setCustomFilterData } from "../../redux/features/dashboard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Button } from "@mui/material";
import { Dispatch } from "redux";

interface CustomDatePickerProps {
  open: boolean;
  onClose: () => void;
  dispatchFunction: any;
}
const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  open,
  onClose,
  dispatchFunction,
}) => {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges: any) => {
    ranges.selection.startDate.setHours(0, 0, 0, 0);
    ranges.selection.endDate.setHours(23, 59, 59, 999);
    setSelection(ranges.selection);
  };

  const applyFilter = () => {
    dispatch(
      dispatchFunction({
        previous: selection.startDate,
        current: selection.endDate,
      })
    );
    onClose(); // Close the modal after applying the filter
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: 400, backgroundColor: "white", padding: 16 }}>
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DateRange
          ranges={[selection]}
          onChange={handleSelect}
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={applyFilter}
          style={{ marginTop: 16 }}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default CustomDatePicker;
