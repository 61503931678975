import React, { useEffect, useState } from "react";
import "./appmodal.scss";
// import { Order } from "../../interfaces/orders";
import { handleOrderItem } from "../../redux/actions/ordersAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppSelector } from "../../redux/store";
import { Box, TableHead, Typography } from "@mui/material";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import OrderDetailsPlaceholders from "../../pages/Orders/Placeholders/OrderDetailsPlaceholders";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment-timezone";
import { handleAppOrderListingDashboard } from "../../redux/actions/dashboardAction";
import {
  formatOrdersDate,
  getSourceType,
} from "../../services/common/commonFunctions";
import { IAppOrderListing } from "../../interfaces";
import OrderDetails from "../../pages/Orders/OrderDetails";
import { useNavigate } from "react-router-dom";

export const AppOrdersModel = ({
  open,
  setOpen,
  isLoading,
  name,
  filterData,
  customFilterData,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  name: string;
  filterData: string;
  customFilterData?: {
    current: Date; // Provide the initial value you want here
    previous: Date; // Provide the initial value you want here
  };
}) => {
  const dispatch = useDispatch();
  const appOrderList: any = useAppSelector(
    (state) => state.dashboard.appOrderList
  );
  const navigate = useNavigate();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const gotoProfile = (id: number) => {
    navigate(`/customer/${id}`);
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    width: "70%",
    minHeight: "93vh",
    maxHeight: "93vh",
  };

  const headCellStyle = {
    backgroundColor: "#CD4D4B",
    color: "white",
    paddingTop: "8px",
    paddingBottom: "8px",
  };

  useEffect(() => {
    if (
      customFilterData?.current &&
      customFilterData?.previous &&
      filterData == "customFilter"
    ) {
      handleAppOrderListingDashboard(dispatch, filterData, customFilterData);
    } else {
      handleAppOrderListingDashboard(dispatch, filterData);
    }
  }, [filterData, customFilterData, open]);

  // const closeIconStyle = {
  //   position: "absolute" as "absolute",
  //   top: 0,
  //   right: 0,
  //   padding: "8px",
  //   cursor: "pointer",
  // };
  const formatDateAndTime = (dateString: any, noChar?: boolean) => {
    const easternTime = moment.tz(dateString, "America/New_York");
    let character = noChar ? "," : "at";
    const formattedDateAndTime = easternTime.format(
      `MMM D, YYYY [${character}] h:mm A`
    );
    return formattedDateAndTime;
  };

  const handleOpenDetails = (item: string) => {
    console.log(item);

    setSelectedItem(item);
    setDetailsOpen(true);
  };
  return (
    <>
      {" "}
      <OrderDetails
        open={detailsOpen}
        setOpen={setDetailsOpen}
        selectedId={selectedItem}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="OrderDetails" sx={style} p={5}>
          <button className="circleButtonV1">
            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{ width: "18px", height: "18px" }}
            />
          </button>
          {isLoading ? (
            // Loading overlay
            <div className="loading-overlay">
              <div>
                <OrderDetailsPlaceholders />
              </div>{" "}
            </div>
          ) : (
            <Box>
              <Box
                sx={{
                  borderBottom: "1px solid #EDEEF1",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              ></Box>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  border: "none",
                  maxHeight: " calc(100vh - 260px)",
                  overflow: "auto",
                }}
              >
                <Table sx={{ minWidth: 350 }}>
                  <TableHead sx={{ position: "sticky", top: "0" }}>
                    <TableRow>
                      <TableCell component="td" scope="row" sx={headCellStyle}>
                        Restaurant
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "70%",
                          minWidth: "200px",
                          ...headCellStyle,
                        }}
                      >
                        Customer
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "70%",
                          minWidth: "200px",
                          ...headCellStyle,
                        }}
                      >
                        Source
                      </TableCell>{" "}
                      <TableCell
                        align="center"
                        sx={{
                          width: "70%",
                          minWidth: "200px",
                          ...headCellStyle,
                        }}
                      >
                        Order ID
                      </TableCell>{" "}
                      <TableCell
                        align="center"
                        sx={{
                          width: "70%",
                          minWidth: "200px",
                          ...headCellStyle,
                        }}
                      >
                        Date and Time
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "70%",
                          minWidth: "200px",
                          ...headCellStyle,
                        }}
                      >
                        View Profile
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {appOrderList && appOrderList.length ? (
                      appOrderList?.map(
                        (order: IAppOrderListing, index: any) => (
                          <TableRow
                            onClick={() => {
                              handleOpenDetails(order.id);
                            }}
                            key={index}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell> {order?.restaurant ?? ""}</TableCell>
                            <TableCell align="center">
                              {order?.customer ?? ""}
                            </TableCell>
                            <TableCell align="center">
                              {getSourceType(order.source)}
                            </TableCell>{" "}
                            <TableCell align="center">
                              {order?.orderId ?? ""}
                            </TableCell>{" "}
                            <TableCell align="center">
                              {formatOrdersDate(order?.time ?? "")}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              <button
                                className={`OD-ToggleBtn transparent-btn-md selected  `}
                                onClick={() => gotoProfile(order.phone)}
                              >
                                Profile
                              </button>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          style={{ textAlign: "center", opacity: 0.5 }}
                        >
                          No Results
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};
