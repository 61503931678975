import React from "react";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { graphData } from "../../interfaces";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartDataset,
  Element,
  BubbleDataPoint,
  Point,
  ChartTypeRegistry,
} from "chart.js";
import { DataXY } from "../../interfaces";
import {
  formatKeyToReadable,
  formatLargeNumber,
} from "../../services/common/commonFunctions";

// Import the necessary chart.js components and scales
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);
export const LineChartComponent = ({
  current,
  previous,
  projected,
  dateFilter,
  FilterUnit,
}: graphData) => {
  const getTooltipFormat = (unit: any) => {
    if (unit === "day" && dateFilter) {
      return "MMM D, YYYY"; // Customize as needed
    } else if (unit === "month") {
      return "MMM YYYY"; // Customize as needed
    } else if (unit === "hour") {
      return "MMM D, YYYY h:mm A"; // Format for day with hours and minutes
    } else if (unit === "years") {
      return "YYYY"; // Customize as needed
    }
    return "ll"; // Default format if unit is not recognized
  };
  let options: any = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: FilterUnit,
          tooltipFormat: getTooltipFormat(FilterUnit),
          displayFormats: {
            day: "MMM D, ddd",
            month: "MMM YYYY",
            year: "YYYY",
            hour: "h:mm A",
          },
          // timezone: "UTC", // Set the timezone to 'UTC'
        },
        ticks: {
          source: "auto",
          autoSkip: true,
        },
      },
    },
  };

  const predictedData = Array.from(current ?? []);
  const predictedPreviousData = Array.from(previous ?? []);
  let xLabels: any = [];
  let currentData: any = predictedData;
  let previousData: any = predictedPreviousData;
  if (dateFilter == "monthToDate" && !projected) {
    xLabels = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
    currentData = xLabels.map((label: any) => {
      const dataPoint = predictedData.find((item) => item.x === label);
      return dataPoint ? dataPoint.y : 0;
    });
    previousData = xLabels.map((label: any) => {
      const dataPoint = predictedPreviousData.find((item) => item.x === label);
      return dataPoint ? dataPoint.y : 0;
    });
    options = {
      scales: {
        x: {
          // type: "time",
          time: {
            unit: FilterUnit,
            tooltipFormat: getTooltipFormat(FilterUnit),
            displayFormats: {
              day: "MMM D, ddd",
              month: "MMM YYYY",
              year: "YYYY",
              hour: "h:mm A",
            },
            // timezone: "UTC", // Set the timezone to 'UTC'
          },
          ticks: {
            source: "auto",
            autoSkip: true,
            min: 1, // Set the minimum value for x-axis
            max: 31, // Set the maximum value for x-axis
            stepSize: 1, // Specify the step size if needed
          },
        },
      },
    };
  } else if (dateFilter == "30days" && !projected) {
    xLabels = Array.from({ length: 30 }, (_, i) => (i + 1).toString());
    currentData = xLabels.map((label: any) => {
      const dataPoint = predictedData.find((item) => item.x === label);
      return dataPoint ? dataPoint.y : 0;
    });
    previousData = xLabels.map((label: any) => {
      const dataPoint = predictedPreviousData.find((item) => item.x === label);
      return dataPoint ? dataPoint.y : 0;
    });
    options = {
      scales: {
        x: {
          // type: "time",
          time: {
            unit: FilterUnit,
            tooltipFormat: getTooltipFormat(FilterUnit),
            displayFormats: {
              day: "MMM D, ddd",
              month: "MMM YYYY",
              year: "YYYY",
              hour: "h:mm A",
            },
            // timezone: "UTC", // Set the timezone to 'UTC'
          },
          ticks: {
            source: "auto",
            autoSkip: true,
            min: 1, // Set the minimum value for x-axis
            max: 30, // Set the maximum value for x-axis
            stepSize: 1, // Specify the step size if needed
          },
        },
      },
    };
  } else if (dateFilter == "weekSoFar" && !projected) {
    xLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    currentData = xLabels.map((label: any) => {
      const dataPoint = predictedData.find((item) => item.x === label);
      return dataPoint ? dataPoint.y : 0;
    });
    previousData = xLabels.map((label: any) => {
      const dataPoint = predictedPreviousData.find((item) => item.x === label);
      return dataPoint ? dataPoint.y : 0;
    });
    options = {
      scales: {
        x: {
          // type: "time",
          time: {
            unit: FilterUnit,
            tooltipFormat: getTooltipFormat(FilterUnit),
            displayFormats: {
              day: "MMM D, ddd",
              month: "MMM YYYY",
              year: "YYYY",
              hour: "h:mm A",
            },
            // timezone: "UTC", // Set the timezone to 'UTC'
          },
          ticks: {
            source: "auto",
            autoSkip: true,
          },
        },
      },
    };
  } else if (dateFilter == "7days") {
    xLabels = projected
      ? []
      : [
          "Sunday (2:00-9:59)",
          "Sunday (10:00-17:59)",
          "Sunday (18:00-1:59)",
          "Monday (2:00-9:59)",
          "Monday (10:00-17:59)",
          "Monday (18:00-1:59)",
          "Tuesday (2:00-9:59)",
          "Tuesday (10:00-17:59)",
          "Tuesday (18:00-1:59)",
          "Wednesday (2:00-9:59)",
          "Wednesday (10:00-17:59)",
          "Wednesday (18:00-1:59)",
          "Thursday (2:00-9:59)",
          "Thursday (10:00-17:59)",
          "Thursday (18:00-1:59)",
          "Friday (2:00-9:59)",
          "Friday (10:00-17:59)",
          "Friday (18:00-1:59)",
          "Saturday (2:00-9:59)",
          "Saturday (10:00-17:59)",
          "Saturday (18:00-1:59)",
        ];
    currentData = projected
      ? predictedData
      : xLabels.map((label: any) => {
          const dataPoint = predictedData.find((item) => item.x === label);
          return dataPoint ? dataPoint.y : 0;
        });
    previousData = projected
      ? predictedPreviousData
      : xLabels.map((label: any) => {
          const dataPoint = predictedPreviousData.find(
            (item) => item.x === label
          );
          return dataPoint ? dataPoint.y : 0;
        });

    options = {
      scales: {
        x: {
          // type: "time",
          time: {
            unit: FilterUnit,
            tooltipFormat: getTooltipFormat(FilterUnit),
            displayFormats: {
              day: "MMM D, ddd",
              month: "MMM YYYY",
              year: "YYYY",
              hour: "h:mm A",
            },
            // timezone: "UTC", // Set the timezone to 'UTC'
          },
          ticks: {
            source: "auto",
            autoSkip: true,
          },
        },
      },
    };
  }

  // Map the data points to the corresponding labels
  const data = {
    labels: xLabels,
    datasets: [
      {
        label: projected
          ? `Current`
          : `This ${formatKeyToReadable(dateFilter as string)} `,
        data: currentData,
        borderColor: "green",
        backgroundColor: "green",
        lineTension: 0.5,
        borderWidth: 5,
      },
      {
        label: projected
          ? `Projected `
          : `Last ${formatKeyToReadable(dateFilter as string)} `,
        data: previousData,
        borderColor: projected ? "blue" : "grey",
        backgroundColor: projected ? "blue" : "grey",
        borderWidth: 5,
        lineTension: 0.5,
      },
    ],
  };

  return <Line className="lineChart" options={options} data={data} />;
};
