import { GET, POST } from "../../services/common/http";
import { BASE_URL } from "../../services/common/const";

import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../features/auth";
import {
  setColorMap,
  setCustomerInsightData,
  setMapDetailsData,
  setOrderReferenceList,
  setRestaurantList,
} from "../features/insight";

export const handleGetCustomerInsights = async (
  dispatch: Dispatch<AnyAction>,
  filterValue: string,
  customFilterData?: any // Ensure customFilterData matches the type
) => {
  dispatch(setLoading(true));
  try {
    let data: any = {
      filterType: filterValue,
    };
    if (customFilterData && filterValue == "customFilter") {
      data = {
        filterType: filterValue,
        previous: customFilterData.previous,
        current: customFilterData.current,
      };
    }
    const response: any = await POST(
      `${BASE_URL}/insights/customer-insights`,
      data
    );
    if (response.data !== null) {
      let insightsData = response.data.insights || {};

      dispatch(setCustomerInsightData(insightsData));
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleConversionDashboard", error);
    dispatch(setLoading(false));
  }
};

export const handleGetRestaurantList = async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true));
  try {
    const response: any = await GET(`${BASE_URL}/insights/restaurant-list`);
    if (response.data !== null) {
      let restaurantData = response.data.restaurantList || [];

      dispatch(setRestaurantList(restaurantData));
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleConversionDashboard", error);
    dispatch(setLoading(false));
  }
};

export const handleGetRestaurantInsights = async (
  dispatch: Dispatch<AnyAction>,
  restaurant: string,
  filterValue: string,
  customFilterData?: any // Ensure customFilterData matches the type
) => {
  dispatch(setLoading(true));
  try {
    let data: any = {
      filterType: filterValue,
      restaurant: restaurant,
    };
    if (customFilterData && filterValue == "customFilter") {
      data = {
        filterType: filterValue,
        previous: customFilterData.previous,
        current: customFilterData.current,
        restaurant: restaurant,
      };
    }
    const response: any = await POST(
      `${BASE_URL}/insights/restaurant-insights`,
      data
    );
    if (response.data !== null) {
      let insightsData = response.data.insights || {};

      dispatch(setCustomerInsightData(insightsData));
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleConversionDashboard", error);
    dispatch(setLoading(false));
  }
};

export const handleGetMapDetails = async (
  dispatch: Dispatch<AnyAction>,
  filterValue: string,
  mapFilter: string,

  customFilterData?: any // Ensure customFilterData matches the type
) => {
  dispatch(setLoading(true));
  try {
    let data: any = {
      filterType: filterValue,
      mapFilter: mapFilter,
    };
    if (customFilterData && filterValue == "customFilter") {
      data = {
        filterType: filterValue,
        mapFilter: mapFilter,
        previous: customFilterData.previous,
        current: customFilterData.current,
      };
    }
    const response: any = await POST(`${BASE_URL}/insights/map-details`, data);
    if (response.data !== null) {
      let mapData = response.data || [];
      console.log(response.data, "this is response");

      dispatch(setMapDetailsData(mapData));
      let mapColor = response.data.colorMap;
      setColorMap(mapColor);
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleConversionDashboard", error);
    dispatch(setLoading(false));
  }
};

export const handleGetMapRestaurantDetails = async (
  dispatch: Dispatch<AnyAction>,
  restaurant: string,

  filterValue: string,
  mapFilter: string,

  customFilterData?: any // Ensure customFilterData matches the type
) => {
  dispatch(setLoading(true));
  try {
    let data: any = {
      filterType: filterValue,
      mapFilter: mapFilter,
      restaurant: restaurant,
    };
    if (customFilterData && filterValue == "customFilter") {
      data = {
        filterType: filterValue,
        mapFilter: mapFilter,
        previous: customFilterData.previous,
        current: customFilterData.current,
        restaurant: restaurant,
      };
    }
    const response: any = await POST(
      `${BASE_URL}/insights/restaurant-map`,
      data
    );

    if (response.data !== null) {
      let mapData = response.data || [];
      console.log(response.data, "this is response");

      dispatch(setMapDetailsData(mapData));
      let mapColor = response.data.colorMap;
      setColorMap(mapColor);
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleConversionDashboard", error);
    dispatch(setLoading(false));
  }
};

export const handleGetOrderReferenceList = async (
  dispatch: Dispatch<AnyAction>,
  totalOrderReferences: any,
  setLoading: any
) => {
  setLoading(true);
  try {
    let data: any = {
      totalOrderReferences,
    };

    const response: any = await POST(`${BASE_URL}/order/order-reference`, data);

    if (response.data !== null) {
      let orderListData = response.data.ordersList || [];
      console.log(response.data, "this is response");

      dispatch(setOrderReferenceList(orderListData));

      setLoading(false);
    } else if (response.error) {
      setLoading(false);
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleGetOrderReferenceList", error);
    dispatch(setLoading(false));
  }
};
