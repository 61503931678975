import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  graphData: {},
  filter: "24hours",
  restaurantData: [],
  customFilterData: {
    current: new Date(), // Provide the initial value you want here
    previous: new Date(), // Provide the initial value you want here
  },
  appOrderList: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardData: (state, action) => {
      state.data = action.payload;
    },
    setGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    setAppOrdersData: (state, action) => {
      state.appOrderList = action.payload;
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filter = "24hours";
      } else {
        state.filter = action.payload;
      }
    },
    setRestaurantData: (state, action) => {
      state.restaurantData = action.payload;
    },
    setCustomFilterData: (state, action) => {
      state.customFilterData = action.payload;
    },
  },
});

export const {
  setDashboardData,
  setGraphData,
  setFilterData,
  setRestaurantData,
  setCustomFilterData,
  setAppOrdersData,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
