import React from "react";
import { GET, POST } from "../../services/common/http";
import { BASE_URL } from "../../services/common/const";
import { setOrdersData, setSelectedData } from "../features/orders";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../features/auth";
import { SearchPostOrder } from "../../interfaces/orders";

export const handleOrdersList = async (
  dispatch: Dispatch<AnyAction>,
  data: SearchPostOrder,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  setPage: React.Dispatch<React.SetStateAction<number>>
) => {
  dispatch(setLoading(true));
  try {
    const response: any = await POST(`${BASE_URL}/order/list`, data);
    if (response.data !== null) {
      dispatch(setOrdersData(response.data.orders));
      if (!response.data.orders.length) setPage(1)
      setTotal(response.data.totalPages)
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleOrdersList", error);
    dispatch(setLoading(false));
  }
};

export const handleOrderItem = async (
  dispatch: Dispatch<AnyAction>,
  id:string | undefined
) => {
  dispatch(setLoading(true));
  try {
    const response: any = await GET(`${BASE_URL}/order/item/${id}`);
    if (response.data !== null) {
      dispatch(setSelectedData(response.data));
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);
      alert("something went wrong");
      return;
    }
  } catch (error) {
    console.log("err in handleOrderItem", error);
    dispatch(setLoading(false));
  }
};