import React, { useEffect, lazy, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PostLogin from "./PostLogin";
import { Layout } from "./Layout";

import { useAppSelector } from "../redux/store";
import Dashboard from "../pages/Dashboard/Dashboard";
import AdvertisementReports from "../pages/PromotionalAdvertisement/AdvertisementReports";
import RestaurantPositionListing from "../pages/RestaurantPositionListing/RestaurantPositionListing";
const Settings = lazy(() => import("../pages/Settings/Settings"));
const Orders = lazy(() => import("../pages/Orders/Orders"));
const CouponsList = lazy(() => import("../pages/Coupons/Coupons"));
const Conversion = lazy(() => import("../pages/Conversion/Conversion"));
const TermsAndConditions = lazy(
  () => import("../pages/TermAndConditions/TermAndConditions")
);
const CustomerDetailsInsights = lazy(
  () => import("../Components/CustomerDetails/CustomerDetailsInsights")
);

const CustomerReports = lazy(
  () => import("../pages/CustomerReports/CustomerReports")
);
const CustomerInsights = lazy(
  () => import("../pages/Insights/CustomerInsights")
);
const CustomerDetails = lazy(
  () => import("../pages/CustomerDetails/CustomerDetails")
);
const CustomerListing = lazy(
  () => import("../pages/CustomerDetails/CustomerListing")
);
const PromotionalCoupons = lazy(
  () => import("../pages/PromotionalCoupons/PromotionalCoupons")
);
const PromotionalWheel = lazy(
  () => import("../pages/PromotionalWheel/PromotionalWheel")
);
const PromotionalAdvertisement = lazy(
  () => import("../pages/PromotionalAdvertisement/PromotionalAdvertisement")
);
const PromotionalPromoDay = lazy(
  () => import("../pages/PromotionalPromoDay/PromotionalPromoDay")
);
const CampaignPage = lazy(() => import("../pages/Campaign/Campaign"));
const PromotionalPoints = lazy(
  () => import("../pages/PromotionalPoints/PromotionalPoints")
);
const Notifications = lazy(
  () => import("../pages/Notifications/Notifications")
);
const Reports = lazy(() => import("../pages/Reports/Reports"));

export default function UserRoutes() {
  const navigate = useNavigate();

  //redux state
  const token = useAppSelector((state) => state.auth.jwtToken);
  const user = useAppSelector((state) => state.auth.UserData);

  useEffect(() => {
    if (!token || !user) {
      navigate("/");
    }
  }, [token, user]);
  return (
    <Routes>
      <Route element={<PostLogin />}>
        <Route
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
          path="/dashboard"
        />

        <Route
          element={
            <Layout>
              <Settings />
            </Layout>
          }
          path="/settings"
        />
        <Route
          element={
            <Layout>
              <CustomerReports />
            </Layout>
          }
          path="/reports"
        />
        <Route
          element={
            <Layout>
              <CouponsList />
            </Layout>
          }
          path="/coupons"
        />
        <Route
          element={
            <Layout>
              <Orders />
            </Layout>
          }
          path="/orders"
        />
      </Route>
      <Route
        element={
          <Layout>
            <Conversion />
          </Layout>
        }
        path="/conversion"
      />
      <Route
        element={
          <Layout>
            <CustomerInsights />
          </Layout>
        }
        path="/insight"
      />
      <Route
        element={
          <Layout>
            <CustomerListing />
          </Layout>
        }
        path="/customer"
      />
      <Route
        element={
          <Layout>
            <TermsAndConditions />
          </Layout>
        }
        path="/termsAndConditions"
      />
      <Route
        element={
          <Layout>
            <CustomerDetails />
          </Layout>
        }
        path="/customer/:id"
      />{" "}
      <Route
        element={
          <Layout>
            <CustomerDetailsInsights />
          </Layout>
        }
        path="/customer-details/:id"
      />
      <Route
        element={
          <Layout>
            <AdvertisementReports />
          </Layout>
        }
        path="/campaign/:id"
      />
      <Route
        element={
          <Layout>
            <PromotionalCoupons />
          </Layout>
        }
        path="/promotional/coupon"
      />{" "}
      <Route
        element={
          <Layout>
            <PromotionalWheel />
          </Layout>
        }
        path="/promotional/wheel"
      />{" "}
      <Route
        element={
          <Layout>
            <Reports />
          </Layout>
        }
        path="/promotional/reports"
      />{" "}
      <Route
        element={
          <Layout>
            <PromotionalAdvertisement />
          </Layout>
        }
        path="/promotional/advertiser"
      />
      <Route
        element={
          <Layout>
            <PromotionalPromoDay />
          </Layout>
        }
        path="/promotional/promoday"
      />
      <Route
        element={
          <Layout>
            <CampaignPage />
          </Layout>
        }
        path="/promotional/campaign"
      />{" "}
      <Route
        element={
          <Layout>
            <PromotionalPoints />
          </Layout>
        }
        path="/promotional/points"
      />
      <Route
        element={
          <Layout>
            <Notifications />
          </Layout>
        }
        path="/notifications"
      />
      <Route
        element={
          <Layout>
            <RestaurantPositionListing />
          </Layout>
        }
        path="/restaurant-listing"
      />
    </Routes>
  );
}
