import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { orderFilter } from "../interfaces";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useAppSelector } from "../redux/store";
import { useState } from "react";
import CusomDatePicker from "./DatePicker/CusomDatePicker";
import leftArrow from "../assets/icons/iconsStatic/leftArrow.svg";

export const OrderFilters = ({
  filterData,
  handleFilters,
  refreshData,
  setOpen,
  open,
  isConversion,
  backFunction,
  restautantName,
  isCustomerTablesOpen,
  CustomDispatchFunction,
  selectedRestaurant,
  handleDropdownChange,
  isRestaurantFilter,
}: orderFilter) => {
  const isLoading = useAppSelector((state) => state.auth.loading);
  const restaurantList: any = useAppSelector(
    (state) => state.insights.restaurantList
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item sm={12}>
        {isConversion && isCustomerTablesOpen && (
          <button
            onClick={backFunction}
            className="btn-white customized"
            style={{ marginBottom: "1.5rem" }}
          >
            <img src={leftArrow} alt="" />
            <span>Back</span>
          </button>
        )}

        {isCustomerTablesOpen ? (
          <h5 style={{ margin: "4px" }}>{restautantName}</h5>
        ) : (
          ""
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            className="tbgv1"
            color="success"
            size="small"
            value={filterData}
            exclusive
            onChange={handleFilters}
            aria-label="Platform"
          >
            <ToggleButton value="allTime">All Time</ToggleButton>
            <ToggleButton value="12months"> 12 months</ToggleButton>
            <ToggleButton value="6months">6 Month</ToggleButton>
            <ToggleButton value="30days">30 days</ToggleButton>
            <ToggleButton value="7days">7 days</ToggleButton>
            <ToggleButton value="24hours">24 hours</ToggleButton>
            <ToggleButton value="8hours">8 hours</ToggleButton>
            <ToggleButton value="customFilter">Custom</ToggleButton>
            {filterData == "customFilter" && open && (
              <CusomDatePicker
                onClose={handleClose}
                open={open}
                dispatchFunction={CustomDispatchFunction}
              />
            )}
          </ToggleButtonGroup>

          {isRestaurantFilter && (
            <Box sx={{ marginLeft: "auto", marginRight: "1rem" }}>
              <Select
                value={selectedRestaurant}
                onChange={handleDropdownChange} // Corrected prop name here
                displayEmpty
                sx={{ ml: 2, height: "100%" }}
                className="selectV4"
              >
                <MenuItem value="">All Restaurant</MenuItem>
                {restaurantList?.map((restaurant: any) => (
                  <MenuItem key={restaurant} value={restaurant}>
                    {restaurant}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          {!isConversion && (
            <button
              disabled={isLoading}
              onClick={refreshData}
              className="btn-white font-Reem-Kufi"
            >
              Refresh
            </button>
          )}
        </Box>
      </Grid>
      {!isConversion && (
        <Grid item>
          <ToggleButtonGroup
            className="tbgv1"
            color="warning"
            size="small"
            value={filterData}
            exclusive
            onChange={handleFilters}
            aria-label="Platform"
          >
            <ToggleButton value="monthToDate">Month to date</ToggleButton>
            <ToggleButton value="weekSoFar">Week So Far</ToggleButton>
            <ToggleButton value="today">Today</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
    </Grid>
  );
};
