import { Box, Typography } from "@mui/material";

export const CardLoader = () => {
  return (
    <Box>
      {/* <Typography
        className="loads"
        sx={{ height: 20, borderRadius: 2 }}
        mb={1}
      ></Typography>
      <Typography
        className="loads"
        sx={{
          height: 15,
          borderRadius: 2,
          width: 100,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></Typography> */}
      <Typography
        className="loads"
        sx={{
          height:40,
          borderRadius: 2,
          width: 200,
          marginLeft: "auto",
          marginRight: "auto",
        }}
        my={1}
      ></Typography>
      
      {/* <Typography
        className="loads"
        sx={{
          position: "absolute",
          left: "50%",
          bottom: -15,
          transform: "translateX(-50%)",
          height: 15,
          borderRadius: 2,
          width: 150,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></Typography> */}
    </Box>
  );
};
