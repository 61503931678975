import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  restaurantList: [
    {
      isPositionUpdate: false,
      restaurantId: "",
      logo: "",
      logoMd: "",
      logoLg: "",
      logoXl: "",
      url: "",
      name: "",
      positionIndex: 0,
      columIndex: 0,
      createdAt: "",
      updatedAt: "",
      id: "",
    },
  ],
  maxPositionIndex: 0,
  maxColumnIndex: 2,
};
export const restaurantPositionSlice = createSlice({
  name: "restaurantPosition",
  initialState,
  reducers: {
    setRestaurantPositionData: (state, action) => {
      state.restaurantList = action.payload;
    },
    setRestaurantPositionMaxPositionIndex: (state, action) => {
      state.maxPositionIndex = action.payload;
    },
    setRestaurantPositionMaxColumnIndex: (state, action) => {
      state.maxColumnIndex = action.payload;
    },
  },
});

export const {
  setRestaurantPositionData,
  setRestaurantPositionMaxPositionIndex,
  setRestaurantPositionMaxColumnIndex,
} = restaurantPositionSlice.actions;
export default restaurantPositionSlice.reducer;
