import React, { useEffect, useState } from "react";
import "./Orders.scss";
// import { Order } from "../../interfaces/orders";
import { handleOrderItem } from "../../redux/actions/ordersAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Box, Typography } from "@mui/material";
import OrderDetail from "./OrderDetail";
import Product from "./Product";
import TrackingDetails from "./TrackingDetails";
import Modal from "@mui/material/Modal";
import { OrderData } from "../../interfaces/orders";

import OrderDetailsPlaceholders from "./Placeholders/OrderDetailsPlaceholders";
import CrossIcon from "../../assets/icons/iconsStatic/crossRedIcon.svg";

export default function OrderDetails({
  open,
  setOpen,
  selectedId,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedId: string;
}) {
  const dispatch = useDispatch();
  const selectedData = useSelector(
    (state: typeof RootState) => state.orders.selectedData as OrderData
  );
  const [toggleTitle, setToggleTitle] = useState("orderDetails");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true); // Start loading

      if (selectedId) {
        await handleOrderItem(dispatch, selectedId);
      }

      setToggleTitle("orderDetails");
      setIsLoading(false); // Stop loading
    }

    fetchData();
  }, [selectedId]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    width: "70%",
    minHeight: "93vh",
    maxHeight: "93vh",
    borderRadius: "1rem",
  };

  if (!selectedData || Object.keys(selectedData).length === 0) return <></>;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="OrderDetails" sx={style} p={2}>
        <button
          className="transparent-btn-md absolutePositionedTopRight"
          onClick={() => setOpen(false)}
        >
          <img src={CrossIcon} alt="close" />
        </button>
        {isLoading ? (
          // Loading overlay
          <div className="loading-overlay">
            <div>
              <OrderDetailsPlaceholders />
            </div>{" "}
          </div>
        ) : (
          <Box>
            <Box
              sx={{
                borderBottom: "1px solid #EDEEF1",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  paddingBottom: "20px",
                  paddingTop: "8px",
                }}
              >
                <button
                  className={`OD-ToggleBtn transparent-btn-md ${
                    toggleTitle === "orderDetails" && "selected"
                  }`}
                  onClick={() => setToggleTitle("orderDetails")}
                >
                  Order Details
                </button>
                <button
                  className={`OD-ToggleBtn transparent-btn-md ${
                    toggleTitle === "product" && "selected"
                  }`}
                  onClick={() => setToggleTitle("product")}
                >
                  Product
                </button>

                {
                  // Show TrackingDetails only if the order type is DELIVERY
                  ["DELIVERY", "delivery"].includes(
                    selectedData?.orderType
                  ) && (
                    <button
                      className={`OD-ToggleBtn transparent-btn-md ${
                        toggleTitle === "trackingDetails" && "selected"
                      }`}
                      onClick={() => setToggleTitle("trackingDetails")}
                    >
                      Tracking Details
                    </button>
                  )
                }
              </Box>
            </Box>
            {toggleTitle === "orderDetails" && (
              <OrderDetail data={selectedData} />
            )}
            {toggleTitle === "product" && <Product data={selectedData} />}
            {toggleTitle === "trackingDetails" && (
              <TrackingDetails data={selectedData} />
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}
