import {
  Alert,
  Box,
  TextField,
  Typography,
  Grid,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import logo from "../../assets/images/foodies-logo-main.png";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import "./SignIn.scss";
import { Login } from "../../services/AuthService";
import Lottie from "react-lottie";

import animationData from "../../lottie/delivery-man.json";
import { useNavigate } from "react-router-dom";
import { LoginData } from "../../interfaces";
import { setToken, setUserData } from "../../redux/features/auth";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import "../Settings/Settings.scss";

const formInitialValues = {
  username: "",
  password: "",
};
export default function SignIn() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [showAlert, setAlertMessage] = useState({
    isError: false,
    message: "",
  });

  let navigate = useNavigate();

  const gotoDashboard = () => {
    navigate("/dashboard");
  };

  const valildationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required."),
    password: Yup.string().required("Password is required."),
  });
  const bodyElt = document.querySelector("body");
  if (bodyElt) {
    bodyElt.style.backgroundColor = "#F4F4F5";
    // bodyElt.style.backgroundImage = "url(/background-cover.png)";
    bodyElt.style.backgroundSize = "cover";
    bodyElt.style.width = "auto";
  }

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#25a0ff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e3e3e3",
        borderRadius: 6,
        color: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#E2E0E0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#25a0ff",
      },
    },
  });

  const loginButtonStyles = {
    borderRadius: "1.5rem",
    background: "#00B976",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    textTransform: "capitalize",
    padding: "9.5px 2.1rem 9.5px 2.1rem",
    boxShadow: "none",
    lineHeight: "1.5",
    ":hover": {
      background: "#00B976",
    },

    "> .MuiLoadingButton-loadingIndicator": {
      left: "13px",
    },

    ":disabled": {
      color: "rgba(255 , 255 , 255 , 0.8)",
    },
  };

  const headingStyles = {
    color: "#CD4D4B",
    fontSize: "2rem",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Reem Kufi",
    marginBottom: "2rem",
  };

  const inputLabelStyles = {
    fontWeight: 500,
    fontSize: "12px",
    color: "#CD4D4B",
    marginBottom: "4px",
  };

  const CssTextFieldStyles = {
    marginTop: "0",
    background: "white",
    borderRadius: "1.5rem",
    border: "1px solid #E5E5E5",
    boxShadow: "none",
    // marginBottom: "2rem",
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  async function submitSigninForm(values: LoginData, resetForm: any) {
    setLoading(true);
    let { username, password } = values;
    try {
      const user: any = await Login({
        username: username,
        password: password,
      });
      if (
        user !== undefined &&
        user !== null &&
        user !== "" &&
        user.user !== undefined &&
        user.user !== null &&
        user.user !== ""
      ) {
        const { firstName, lastName, password, userName, id } = user.user;
        const userData = {
          firstName: firstName,
          lastName: lastName,
          password: password,
          username: userName,
          userId: id,
        };

        dispatch(setUserData(userData));
        dispatch(setToken(user.token));
        gotoDashboard();
      } else {
        setAlertMessage({
          isError: true,
          message: user as string,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isError: true,
          message: error as string,
        });
      }
      setTimeout(() => {
        setAlertMessage({
          isError: false,
          message: "",
        });
      }, 7000);
      resetForm(formInitialValues);
    }
  }

  const token = useAppSelector((state) => state.auth.jwtToken);
  const user = useAppSelector((state) => state.auth.UserData);

  useEffect(() => {
    if (token && user) {
      navigate("/dashboard");
    }
  }, [token, user]);

  return (
    <Box className="signin-main-wrapper" mt={12}>
      <Box className="signin-box-left">
        <Box
          className="signin-logo-box"
          sx={{ textAlign: "center", marginBottom: 5 }}
        >
          <img alt="" src={logo} width="272" />
        </Box>

        <Box className="signin-form-box">
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={(values, { resetForm }) => {
              submitSigninForm(values, resetForm);
            }}
            validationSchema={valildationSchema}
          >
            {(props) => {
              return (
                <>
                  <Box marginBottom={1} className="signin-alert-box">
                    {showAlert.isError ? (
                      <Alert className="signin-alert" severity="error">
                        {showAlert.message}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Form className="signin-form">
                    <Box sx={{ maxWidth: 500 }} p={4} className="cardV2">
                      <Typography sx={headingStyles}>Login</Typography>
                      <InputLabel size="normal" sx={inputLabelStyles}>
                        Username
                      </InputLabel>
                      <Box
                        mb={4}
                        sx={{
                          "> div": {
                            marginBottom: "0",
                          },
                        }}
                      >
                        <CssTextField
                          sx={CssTextFieldStyles}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          className="textfield-v1"
                          placeholder="Enter your Username"
                          value={props.values.username}
                          onChange={props.handleChange("username")}
                          onBlur={props.handleBlur}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          error={
                            props.errors.username && props.touched.username
                              ? true
                              : false
                          }
                        ></CssTextField>
                        {props.errors.username && props.touched.username ? (
                          <Typography color="#c52b2b" fontSize={12}>
                            {props.errors.username}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Box>

                      <Box
                        mb={4}
                        sx={{
                          "> div": {
                            marginBottom: "0",
                          },
                        }}
                      >
                        <InputLabel size="normal" sx={inputLabelStyles}>
                          Password
                        </InputLabel>

                        <CssTextField
                          sx={CssTextFieldStyles}
                          className="textfield-v1"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder="Enter your Password"
                          value={props.values.password}
                          onChange={props.handleChange("password")}
                          onBlur={props.handleBlur}
                          type={"password"}
                          autoComplete="off"
                          inputProps={{
                            autoComplete: "off",
                          }}
                          error={
                            props.errors.password && props.touched.password
                              ? true
                              : false
                          }
                        ></CssTextField>
                        {props.errors.password && props.touched.password ? (
                          <Typography color="#c52b2b" fontSize={12}>
                            {props.errors.password}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Box>

                      <Box sx={{ textAlign: "center" }}>
                        <LoadingButton
                          sx={loginButtonStyles}
                          // className="signin-submit-button"
                          loading={loading}
                          loadingPosition="start"
                          size="large"
                          type="submit"
                          startIcon={<></>}
                        >
                          Login
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Box>
      {/* <Grid  alignSelf="center" item md={6} className="signin-box-right">
        <Box  className="lottie-box-outer">
          <Box className="lottie-box">
          <Lottie options={defaultOptions} />
          </Box>
          </Box>
        </Grid> */}
    </Box>
  );
}
