import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dailyRewardList: [
    {
      _id: "",
      text: "",
      type: "",
      userId: "",
      orderTotal: 0,
      points: 0,
      createdAt: "",
      updatedAt: "",
      userData: {
        _id: "",
        email: "",
        phone: "",
        name: "",
      },
    },
  ],
  orderRewardList: [
    {
      _id: "",
      text: "",
      type: "",
      userId: "",
      orderTotal: 0,
      points: 0,
      createdAt: "",
      updatedAt: "",
      userData: {
        _id: "",
        email: "",
        phone: "",
        name: "",
      },
    },
  ],
  TotalPageCount: 1,
};

export const promotionalPointsSlice = createSlice({
  name: "promotionalPoints",
  initialState,
  reducers: {
    setDailyRewardList: (state, action) => {
      state.dailyRewardList = action.payload;
    },
    setOrderRewardList: (state, action) => {
      state.orderRewardList = action.payload;
    },
    setPromotionalPointsTotalPageCount: (state, action) => {
      state.TotalPageCount = action.payload;
    },
  },
});

export const {
  setDailyRewardList,
  setOrderRewardList,
  setPromotionalPointsTotalPageCount,
} = promotionalPointsSlice.actions;
export default promotionalPointsSlice.reducer;
