import { Box } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { OrderData } from "../../interfaces/orders";
import moment from "moment";

function createData(column1: string, column2: string) {
  return { column1, column2 };
}

export default function TrackingDetails({ data }: { data: OrderData }) {
  const rows = [
    createData("service Provider", data.serviceProvider),
    createData(
      "Driver Pick Up",
      `${data?.driverPickUp ? moment(data.driverPickUp).format("h:mm a") : ""}`
    ), // doubt
    createData(
      "Drop Off",
      `${data?.dropOff ? moment(data.dropOff).format("h:mm a") : ""}`
    ), // doubt
  ];

  return (
    <>
      <Box sx={{ overflow: "auto", maxHeight: "calc(100vh - 200px)" }}>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            border: "none",
            maxHeight: "calc(100vh - 260px)",
            marginBottom: "2rem",
          }}
          className="tavleV1 customized2"
        >
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableBody>
              {rows?.map((row) => (
                <TableRow
                  key={row.column1}
                  // sx={{borderBottom: "1px solid rgba(237, 238, 241, 1)"}}
                  sx={{
                    "td, th": {
                      borderBottom: "1px solid rgba(237, 238, 241, 1)",
                    },
                  }}
                >
                  <TableCell component="td" scope="row">
                    {row.column1}
                  </TableCell>
                  <TableCell align="left" sx={{ py: "18px" }}>
                    {row.column2}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box>
          <iframe
            src={data.trackingURL}
            style={{
              width: "500px",
              minWidth: "calc(100% - 10px)",
              maxWidth: "100%",
              minHeight: "400px",
              height: "100vh",
            }}
          ></iframe>
        </Box>
      </Box>
    </>
  );
}
