import React from "react";
import { GET, POST, PUT } from "../../services/common/http";
import { BASE_URL } from "../../services/common/const";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../features/auth";
import { SearchPostCoupon } from "../../interfaces/coupons";
import { setCouponsData } from "../features/coupons";
import {
  setRestaurantPositionData,
  setRestaurantPositionMaxColumnIndex,
  setRestaurantPositionMaxPositionIndex,
} from "../features/restaurantPosition";
import toast from "react-hot-toast";
import { handleApiError } from "../../services/common/commonFunctions";

export const handleRestaurantList = async (
  dispatch: Dispatch<AnyAction>,
  data: string,
  setTotal: React.Dispatch<React.SetStateAction<number>>
) => {
  dispatch(setLoading(true));
  try {
    const response: any = await GET(
      `${BASE_URL}/bh-restaurant/list?search=${data}`
    );

    if (response !== null) {
      dispatch(setRestaurantPositionData(response.restaurantList));
      dispatch(
        setRestaurantPositionMaxPositionIndex(response.maxPositionIndex)
      );
      dispatch(setRestaurantPositionMaxColumnIndex(response.maxColumnIndex));

      setTotal(response.totalCount);
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));
      console.log(response.error);

      return;
    }
  } catch (error) {
    console.log("err in handleOrdersList", error);
    dispatch(setLoading(false));
    const message = handleApiError(error);
    toast.error(message);
  }
};

export const handleSwapRestaurantPosition = async (
  dispatch: Dispatch<AnyAction>,
  restaurantId: string,
  columIndex: number,
  positionIndex: number,
  setTotalPage: any,
  searchTerm: string
) => {
  dispatch(setLoading(true));
  try {
    const data = {
      restaurantId,
      columIndex,
      positionIndex,
    };
    const response: any = await PUT(
      `${BASE_URL}/bh-restaurant/update-position`,
      data
    );

    if (response !== null) {
      toast.success("Restaurant Position updated successfully  ");
      handleRestaurantList(dispatch, searchTerm, setTotalPage);
      dispatch(setLoading(false));
    } else if (response.error) {
      dispatch(setLoading(false));

      return;
    }
  } catch (error) {
    console.log("err in handleOrdersList", error);
    dispatch(setLoading(false));
    const message = handleApiError(error);
    toast.error(message);
  }
};
