import React, { useState, useEffect } from "react";
import { Table, Box, CircularProgress, TextField, Button, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import {
  handleRestaurantList,
  handleSwapRestaurantPosition,
} from "../../redux/actions/restaurantAction";
import { IRestaurantPositionRestaurantListing } from "../../interfaces/restaurantPosition";
import RestaurantPreviewModal from "../../Components/bhRestaurant/RestaurantPreviewModal";

const RestaurantPositionListing: React.FC = () => {
  const dispatch = useDispatch();

  const RestaurantPositionData = useSelector(
    (state: typeof RootState) =>
      state.restaurantPosition
        .restaurantList as IRestaurantPositionRestaurantListing[]
  );

  const loading = useSelector((state: typeof RootState) => state.auth.loading);

  const MaxColumnIndex = useSelector(
    (state: typeof RootState) => state.restaurantPosition.maxColumnIndex
  );
  const MaxPositionIndex = useSelector(
    (state: typeof RootState) => state.restaurantPosition.maxPositionIndex
  );

  const [isPositionEditingIndex, setIsPositionEditingIndex] = useState<
    number | null
  >(null);
  const [isColumnEditingIndex, setIsColumnEditingIndex] = useState<
    number | null
  >(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermTemp, setSearchTermTemp] = useState("");
  const [tempValue, setTempValue] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleRestaurantList(dispatch, searchTerm, setTotalPage);
      if (searchTermTemp !== searchTerm) {
        setPage(1);
        setSearchTermTemp(searchTerm);
      }
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm, page]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#CD4D4B",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFBFD",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setTempValue(Number(value) - 1); // Convert to 0-based index
    }
  };

  const handleColumnFocusOut = () => {
    setError("");
    setIsColumnEditingIndex(null);
  };

  const handlePositionFocusOut = (restaurantId: string) => {
    setError("");
    setIsPositionEditingIndex(null);
  };

  const handleColumnEditClick = (index: number, value: number) => {
    setIsColumnEditingIndex(index);
    setTempValue(value);
    setError("");
  };

  const handlePositionEditClick = (index: number, value: number) => {
    setIsPositionEditingIndex(index);
    setTempValue(value);
    setError("");
  };

  const handlePositionEditSubmit = (
    e: React.KeyboardEvent,
    restaurant: IRestaurantPositionRestaurantListing
  ) => {
    if (e.key === "Enter") {
      if (tempValue + 1 > MaxPositionIndex) {
        setError(`Value cannot be greater than ${MaxPositionIndex + 1}`);
      } else {
        setError("");
        handleSwapRestaurantPosition(
          dispatch,
          restaurant.restaurantId,
          restaurant.columIndex,
          tempValue,
          setTotalPage,
          searchTerm
        );
        setIsPositionEditingIndex(null);
      }
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const handleColumnEditSubmit = (
    e: React.KeyboardEvent,
    restaurant: IRestaurantPositionRestaurantListing
  ) => {
    if (e.key === "Enter") {
      if (tempValue + 1 > MaxColumnIndex) {
        setError(`Value cannot be greater than ${MaxColumnIndex + 1}`);
      } else {
        setError("");
        handleSwapRestaurantPosition(
          dispatch,
          restaurant.restaurantId,
          tempValue,
          restaurant.positionIndex,
          setTotalPage,
          searchTerm
        );
        setIsColumnEditingIndex(null);
      }
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "calc(100vh - 65px)",
        overflow: "auto",
        p: 3,
      }}
    >
      <RestaurantPreviewModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />

      <div className="mx-4">
        <Box mb={2} display="flex" gap={2}>
          <Box sx={{ flex: "1" }}>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                borderRadius: "1.5rem",
                boxShadow: "0px 4px 24px 0px rgba(188, 188, 188, 0.15)",
              }}
            >
              <IconButton
                type="button"
                sx={{ p: "10px", color: "#D56F6E" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Paper>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "1.5rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
          }}
          className="tableV1 customized"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item >
              <Box
                sx={{
                  paddingY: "1rem",
                  paddingX: "1.5rem",
                  color: "#5E768D",
                  fontSize: "12px",
                }}
              >
                Restaurant
              </Box>
            </Grid>
            <Grid item mr={1.5}>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => setModalVisible(true)}
                className="variantOne"
              >
                Show Preview
              </Button>
            </Grid>
          </Grid>




          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Restaurant Name</StyledTableCell>
                <StyledTableCell align="center">Logo</StyledTableCell>
                <StyledTableCell align="center">Position Index</StyledTableCell>
                <StyledTableCell align="center">Column Index</StyledTableCell>
                <StyledTableCell align="center">
                  Position Updated
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : RestaurantPositionData && RestaurantPositionData.length ? (
                RestaurantPositionData.map(
                  (restaurant: IRestaurantPositionRestaurantListing, index) => (
                    <StyledTableRow key={index} style={{ cursor: "pointer" }}>
                      <StyledTableCell>{restaurant.name ?? ""}</StyledTableCell>
                      <StyledTableCell align="center">
                        <img
                          src={restaurant?.logo ?? ""}
                          alt={restaurant?.name ?? "Logo"}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {isPositionEditingIndex === index ? (
                          <TextField
                            value={tempValue + 1} // Convert to 1-based index
                            onChange={handleInputChange}
                            onKeyDown={(e) =>
                              handlePositionEditSubmit(e, restaurant)
                            }
                            onBlur={() =>
                              handlePositionFocusOut(restaurant.restaurantId)
                            }
                            variant="outlined"
                            size="small"
                            error={Boolean(error)}
                            helperText={error}
                            autoFocus
                          />
                        ) : (
                          <Typography
                            onClick={() =>
                              handlePositionEditClick(
                                index,
                                restaurant.positionIndex
                              )
                            }
                          >
                            {restaurant.positionIndex + 1}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {isColumnEditingIndex === index ? (
                          <TextField
                            value={tempValue + 1} // Convert to 1-based index
                            onChange={handleInputChange}
                            onKeyDown={(e) =>
                              handleColumnEditSubmit(e, restaurant)
                            }
                            onBlur={() => handleColumnFocusOut()}
                            variant="outlined"
                            size="small"
                            error={Boolean(error)}
                            helperText={error}
                            autoFocus
                          />
                        ) : (
                          <Typography
                            onClick={() =>
                              handleColumnEditClick(
                                index,
                                restaurant.columIndex
                              )
                            }
                          >
                            {restaurant.columIndex + 1}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>
                          {restaurant.isPositionUpdate
                            ? "Updated"
                            : "Not Updated"}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{ textAlign: "center", opacity: 0.5 }}
                  >
                    No Results
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box
            sx={{
              paddingY: "1rem",
              paddingX: "1.5rem",
              color: "#5E768D",
              fontSize: "12px",
            }}
          ></Box>
        </TableContainer>
      </div>
    </Box>
  );
};

export default RestaurantPositionListing;
