export const fetchFunction = async (
  url: string,
  method: string,
  options: any,
  resolve: any,
  reject: any,
  data?: any
) => {
  return fetch(url, {
    method: method,
    headers: options,
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) =>
      response.json().then((body) => ({
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        data: body,
      }))
    )
    .then((responseJson) => {
      if (!responseJson.ok) {
        if (responseJson.status === 400) {
          if (responseJson.data && responseJson.data.message)
            throw responseJson.data.message;
          else throw responseJson.statusText;
        } else throw responseJson.statusText;
      } else resolve(responseJson.data);
    })
    .catch((error) => {
      reject(error);
    });
};
