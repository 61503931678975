import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timeFrame: "weekSoFar",
  sortOrder: "mostOrders",
  graphFilter: "orders",
};

export const filterSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setTimeFrameData: (state, action) => {
      state.timeFrame = action.payload;
    },
    setSortOrderFilter: (state, action) => {
      state.sortOrder = action.payload;
    },
    setGraphFilter: (state, action) => {
      if(action.payload === null){
        state.graphFilter = "orders"
      }else{
        state.graphFilter = action.payload;
      }
    },
  },
});

export const { setTimeFrameData, setSortOrderFilter, setGraphFilter } =
  filterSlice.actions;
export default filterSlice.reducer;
