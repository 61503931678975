import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [
    {
      name: "",
      email: "",
      coupon: "",
      rewardedDate: "",
      redeemedDate: "",
      status: "",
    },
  ],
  filter: "allTime",
  customFilterData: {
    current: new Date(), // Provide the initial value you want here
    previous: new Date(), // Provide the initial value you want here
  },
  TotalPageCount: 1,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportsData: (state, action) => {
      state.data = action.payload;
    },
    setReportsFilterData: (state, action) => {
      if (action.payload === null) {
        state.filter = "allTime";
      } else {
        state.filter = action.payload;
      }
    },
    setReportsTotalPageCount: (state, action) => {
      state.TotalPageCount = action.payload;
    },
    setCustomFilterData: (state, action) => {
      state.customFilterData = action.payload;
    },
  },
});

export const {
  setReportsData,
  setReportsTotalPageCount,
  setReportsFilterData,
  setCustomFilterData,
} = reportsSlice.actions;
export default reportsSlice.reducer;
