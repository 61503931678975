import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  CouponList: [
    {
      coupon_category: "",
      coupon_code: "",
      count: 0,
      _id: "",
    },
  ],

  CategoryCouponsDetails: {
    total_coupons: 0,
    redeemed_count: 0,
    unredeemed_count: 0,
    coupons: [
      {
        coupon_code: "",
        coupon_description: "",
        coupon_status: "",
        redemption_status: "",
      },
    ],
  },
  paginatedCategoryList: [
    {
      coupon_category: "",
      totalCouponCodes: 0,
      _id: "",
    },
  ],
  filter: "all",
  categoryList: [{ coupon_category: "", _id: "" }],
  TotalPageCount: 1,
};

export const PromotionalCouponsSlice = createSlice({
  name: "promotionalCoupons",
  initialState,
  reducers: {
    setPromotionalCouponList: (state, action) => {
      state.CouponList = action.payload;
    },
    setPromotionalCouponFilter: (state, action) => {
      state.filter = action.payload;
    },
    setPromotionalCouponTotalPageCount: (state, action) => {
      state.TotalPageCount = action.payload;
    },
    setPromotionalCouponCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setPromotionalPaginatedCategoryList: (state, action) => {
      state.paginatedCategoryList = action.payload;
    },
    setPromotionalCategoryCouponDetailList: (state, action) => {
      state.CategoryCouponsDetails = action.payload;
    },
  },
});

export const {
  setPromotionalCouponList,
  setPromotionalCouponTotalPageCount,
  setPromotionalCouponFilter,
  setPromotionalCouponCategoryList,
  setPromotionalPaginatedCategoryList,
  setPromotionalCategoryCouponDetailList,
} = PromotionalCouponsSlice.actions;
export default PromotionalCouponsSlice.reducer;
