import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { OrderData } from "../../interfaces/orders";
import moment from "moment";

function createData(column1: string, column2: string) {
  return { column1, column2 };
}

export default function OrderDetail({ data }: { data: OrderData }) {
  const rows = [
    createData("Order ID", data.orderId),
    createData("Customer Name", data.customerName),
    createData("Total", data.total),
    createData("Order Type", data.orderType),

    createData("Store Name", data.storeName),
    createData("Store URL", data.storeURL), // doubt
    createData("Telephone", data.telephone),
    createData("Push Notifications", data.pushNotifications), //doubt
    createData("Order Status", data.orderStatus),
    createData(
      "Order Placed",
      `${moment(data.orderPlaced).format("MM/DD/YYYY, hh:mm A")}`
    ), //doubt
    createData("Payment Status", data.paymentStatus), //doubt
    createData("Ready At", `${moment(data.readyAt).format("h:mm a")}`),
    createData("Order Method", data.orderMethod), // doubt
    createData("Location", data.location), // doubt
    createData("Address", data.address),
    createData("Refund", data.refund),
    createData("paymentStatus", data.paymentStatus),
  ];

  const filteredRows = rows?.filter(
    (row) =>
      row.column2 !== null && row.column2 !== undefined && row.column2 !== ""
  );

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "none",
          maxHeight: " calc(100vh - 260px)",
          overflow: "auto",
        }}
      >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableBody>
            {filteredRows?.map((row) => (
              <TableRow
                key={row.column1}
                // sx={{borderBottom: "1px solid rgba(237, 238, 241, 1)"}}
                sx={{
                  "td, th": {
                    borderBottom: "1px solid #e5e5e5",
                    borderLeft: "1px solid #e5e5e5",
                  },

                  "td:last-child": {
                    borderRight: "1px solid #e5e5e5",
                  },
                }}
              >
                <TableCell
                  component="td"
                  scope="row"
                  sx={{
                    py: "10px",
                    color: "#C0201E",
                    fontSize: "14px",
                    maxWidth: "200px",
                    width: "200px",
                    minWidth: "200px",
                  }}
                >
                  {row.column1}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    py: "10px",
                    minWidth: "200px",
                    fontSize: "14px",
                  }}
                >
                  {row.column2}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
