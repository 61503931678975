import React from "react";
import "./App.css";
import RootNavigation from "./navigations/RootNavigation";
import { createTheme, ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import "./Components/common.scss";

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#C0201E",
      },
      secondary: {
        main: "#770f35",
      },
      background: {
        default: "#F4F4F5",
      },
      error: {
        main: "#e7101f",
      },
      warning: {
        main: "#f7a614",
      },
      success: {
        main: "#00B976",
      },
    },
    typography: {
      fontFamily: "Poppins",
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {" "}
      <Toaster
        position="top-center"
        gutter={22}
        toastOptions={{
          success: {
            duration: 3000,
          },
          error: {
            duration: 5000,
          },
        }}
      />
      <RootNavigation />
    </ThemeProvider>
  );
}

export default App;
