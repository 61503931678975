import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerList: [],
  selectedFilters: [],
  totalPages: [],
};

export const CustomerReportsSlice = createSlice({
  name: "customerReports",
  initialState,
  reducers: {
    setCustomerReportsList: (state, action) => {
      state.customerList = action.payload;
    },
    setCustomerReportsTotalCount: (state, action) => {
      state.totalPages = action.payload;
    },
    setCustomerReportsSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
  },
});

export const {
  setCustomerReportsList,
  setCustomerReportsTotalCount,
  setCustomerReportsSelectedFilters,
} = CustomerReportsSlice.actions;
export default CustomerReportsSlice.reducer;
