
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { VerifyJwt } from "../../services/AuthService";
import { setToken, setUserData } from "../features/auth";

export const verifyUser = async (dispatch: Dispatch<AnyAction>) => {
    try {
        const res: any = await VerifyJwt();
        if (!res.data) {
            throw new Error("Invalid token");
        }
    } catch (err) {
        dispatch(setToken(""));
        dispatch(setUserData({}))
        console.error(err);
    }
};