import { createSlice } from "@reduxjs/toolkit";
import { CouponsState } from "../../interfaces/coupons";

const initialState: CouponsState = {
  data: [],
};


export const couponsSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    setCouponsData: (state, action) => {
      state.data = action.payload;
    },
  
  },
});

export const { setCouponsData } =
couponsSlice.actions;
export default couponsSlice.reducer;