import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  advertisementList: [
    {
      _id: "",
      companyName: "",
      contactName: "",
      email: "",
      phone: 0,

      createdAt: "",
      updatedAt: "",
    },
  ],
  advertiserCampaigns: [],

  advertisementOptions: [
    {
      _id: "",
      contactName: "",
    },
  ],
  TotalPageCount: 1,
};

export const promotionalAdvertisementSlice = createSlice({
  name: "promotionalAdvertisement",
  initialState,
  reducers: {
    setPromotionalAdvertisementList: (state, action) => {
      state.advertisementList = action.payload;
    },
    setPromotionalAdvertisementOptions: (state, action) => {
      state.advertisementOptions = action.payload;
    },
    setPromotionalAdvertisementTotalPageCount: (state, action) => {
      state.TotalPageCount = action.payload;
    },
    setPromotionalAdvertiserCampaigns: (state, action) => {
      state.advertiserCampaigns = action.payload;
    },
  },
});

export const {
  setPromotionalAdvertisementList,
  setPromotionalAdvertisementTotalPageCount,
  setPromotionalAdvertisementOptions,
  setPromotionalAdvertiserCampaigns,
} = promotionalAdvertisementSlice.actions;
export default promotionalAdvertisementSlice.reducer;
