import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import "./common.scss";

export const RestaurantFilters = ({
  sortValue,
  timeFrame,
  handleRestaurantTime,
  handleRestaurantFilter,
}: any) => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <FormControl className="FormControl">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sortValue}
          onChange={(event) => handleRestaurantFilter(event.target.value)}
          className="select-v1"
        >
          <MenuItem value={"topRestaurants"}>Top Restaurants</MenuItem>
          <MenuItem value={"trending"}>Trending</MenuItem>
          <MenuItem value={"mostOrders"}>Most Orders</MenuItem>
          <MenuItem value={"mostFees"}>Most Fees</MenuItem>
          <MenuItem value={"worstTrending"}>Worst Trending</MenuItem>
        </Select>
      </FormControl>
      <Typography
        sx={{
          marginLeft: "1rem",
          marginRight: "1rem",
          fontSize: "12px",
          color: "#5E768D",
          fontWeight: "600",
        }}
      >
        This
      </Typography>
      <FormControl className="FormControl">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={timeFrame}
          onChange={(event) => handleRestaurantTime(event.target.value)}
          className="select-v1"
        >
          <MenuItem value={"today"}>Day</MenuItem>
          <MenuItem value={"weekSoFar"}>Week</MenuItem>
          <MenuItem value={"monthToDate"}>Months</MenuItem>
          <MenuItem value={"24Hours"}>Past 24 Hours</MenuItem>
          <MenuItem value={"7days"}>Past Week</MenuItem>
          <MenuItem value={"30days"}>Past 30 Days</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
