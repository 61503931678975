import React from "react";
import { Modal, Box, Grid, CardMedia } from "@mui/material";
import { IRestaurantPositionRestaurantListing } from "../../interfaces/restaurantPosition";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { relative } from "path";
import CloseIcon from "@mui/icons-material/Close";
// Heights for different grid item sizes
const ITEM_HEIGHT = [
  [112, 160, 222], // Heights for column 1
  [222, 112, 160], // Heights for column 2
  [160, 222, 112], // Heights for column 3
];

const RestaurantPreviewModal = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: any;
}) => {
  // Redux selector to get restaurant position data
  const RestaurantPositionData = useSelector(
    (state: typeof RootState) =>
      state.restaurantPosition
        .restaurantList as IRestaurantPositionRestaurantListing[]
  );

  // Function to determine which logo size to use based on grid item height
  const getLogo = (
    item: IRestaurantPositionRestaurantListing,
    height: number
  ) => {
    return height === 112
      ? item.logoMd
      : height === 160
      ? item.logoLg
      : height === 222
      ? item.logoXl
      : item.logo;
  };

  // Function to render the grid layout
  const renderGrid = () => {
    const cols: React.JSX.Element[][] = Array.from({ length: 3 }, () => []); // Array to hold grid items in each column
    let colMaxPositionIndex: number[] = [0, 0, 0]; // Array to hold max position index for each column

    // Calculate max position index for each column
    RestaurantPositionData.forEach((item) => {
      if (item.columIndex >= 0 && item.columIndex <= 2) {
        if (item.positionIndex > colMaxPositionIndex[item.columIndex]) {
          colMaxPositionIndex[item.columIndex] = item.positionIndex;
        }
      }
    });

    // Loop through restaurant data again to render
    RestaurantPositionData.forEach(
      (item: IRestaurantPositionRestaurantListing, index) => {
        let lHeight = ITEM_HEIGHT[item.columIndex][item.positionIndex % 3]; // Height for the current grid item

        const view = (
          <Box
            key={`${item.positionIndex}-${item.columIndex}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={lHeight}
            bgcolor="grey.200"
            borderRadius={2}
            mb={2}
            position="relative"
          >
            <CardMedia
              component="img"
              image={getLogo(item, lHeight)}
              style={{ height: "100%", width: "auto" }}
              loading="lazy"
            />
            <span
              style={{
                position: "absolute",
                right: "100%",
                bottom: "0",
                paddingRight: "7px",
                fontSize: "14px",
                color: "#cd4d4b",
              }}
            >
              {item.positionIndex + 1}
            </span>
          </Box>
        );

        cols[item.columIndex].push(view); // Add grid item to respective column
      }
    );

    // Render the grid layout
    return (
      <Grid container columnSpacing={4} className="gridResto">
        {cols.map((col, colIdx) => (
          <Grid item xs={4} key={colIdx}>
            {col}
          </Grid>
        ))}
      </Grid>
    );
  };

  // Return the modal component
  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        bgcolor="background.paper"
        p={4}
        pl={8}
        borderRadius={4}
        boxShadow={24}
        maxWidth="90%"
        maxHeight="90%"
      >
        <div
          style={{
            color: "#cd4d4b",
            fontSize: "14px",
            position: "relative",
          }}
        >
          <button
            onClick={onClose}
            className="circleButtonV1"
            style={{
              right: "-20px",
              top: "-20px",
            }}
          >
            <CloseIcon sx={{ width: "18px", height: "18px" }} />
          </button>
          <div
            style={{
              left: "-32px",
              position: "relative",
            }}
          >
            Column Index<div className="rightArrow"></div>
          </div>
          <Grid
            container
            columnSpacing={4}
            textAlign="center"
            color="#cd4d4b"
            fontSize={14}
          >
            <Grid item xs={4}>
              1
            </Grid>
            <Grid item xs={4}>
              2
            </Grid>
            <Grid item xs={4}>
              3
            </Grid>
          </Grid>
          <div
            style={{
              position: "absolute",
              rotate: "90deg",
              transformOrigin: "left top",
              color: "#cd4d4b",
              fontSize: "14px",
              left: "-25px",
              top: "40px",
            }}
          >
            Position Index <div className="rightArrow"></div>{" "}
          </div>
        </div>
        {renderGrid()} {/* Render the grid layout within the modal */}
      </Box>
    </Modal>
  );
};

export default RestaurantPreviewModal;
