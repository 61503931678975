import React, { useState, useEffect } from "react";
import { Table, Button, Box, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState, useAppSelector } from "../../redux/store";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { AdvertiserCalculation } from "../../Components/PromotionalAdvertisement/AdvertiserCalculation";
import {
  handleGetCampaignAwardedUsersList,
  handleGetCampaignReport,
} from "../../redux/actions/campaignAction";
import { campaignCoinAwardedUserDataInterface } from "../../interfaces/promotionalAdvertisement";

const AdvertisementReports: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const loading = useAppSelector((state) => state.auth.loading);
  const [refresh, setRefresh] = useState(false);
  const campaignCalculationData: any = useAppSelector(
    (state) => state.campaignData.campaignReport
  );

  const campaignRewardedUsersData: campaignCoinAwardedUserDataInterface[] =
    useAppSelector((state) => state.campaignData.awardedUsers);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#CD4D4B",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFBFD",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const fetchFunction = () => {
    dispatch(handleGetCampaignReport({ id }));
  };
  useEffect(() => {
    fetchFunction();
  }, [id]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(
          handleGetCampaignAwardedUsersList({
            campaignId: id,
            setTotalPage,
            totalPage,
            page,
          })
        );
      } catch (error) {
        console.error("Error fetching coupon list", error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [page, id]);

  // Function to format date and time
  const formatDateAndTime = (dateString: any, noChar?: boolean) => {
    const easternTime = moment.tz(dateString, "America/New_York");
    let character = noChar ? "," : "at";
    const formattedDateAndTime = easternTime.format(
      `MMM D, YYYY [${character}] h:mm A`
    );
    return formattedDateAndTime;
  };

  const goBackFunction = () => [navigate("/promotional/advertiser")];
  const handleRefresh = () => {
    setRefresh(!refresh);
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "calc(100vh - 65px)",
        overflow: "auto",
        p: 3,
      }}
    >
      <div className="mx-4">
        <AdvertiserCalculation
          advertiserCalculationDetails={campaignCalculationData}
          goBackFunction={goBackFunction}
        />
        <TableContainer
          component={Paper}
          className="tavleV1 customized"
          sx={{
            borderRadius: "1rem",
            maxHeight: "calc(100vh - 54px)",
            overflow: "auto",
            borderBottom: "none",
          }}
        >
          <Box
            sx={{
              paddingY: "11px",
              paddingX: "1.5rem",
              color: "#5E768D",
              fontSize: "12px",
              position: "sticky",
              top: 0,
              left: 0,
              background: "white",
              width: "100%",
            }}
          >
            Advertisement
          </Box>

          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>User Name</StyledTableCell>

                <StyledTableCell align="center">Coin Rewarded</StyledTableCell>
                <StyledTableCell align="center">Awarded Date</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? ( // Show loader if loading
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : campaignRewardedUsersData &&
                campaignRewardedUsersData.length ? (
                campaignRewardedUsersData?.map(
                  (
                    customer: campaignCoinAwardedUserDataInterface,
                    index: any
                  ) => (
                    <StyledTableRow key={index} style={{ cursor: "pointer" }}>
                      <StyledTableCell>
                        {customer?.name ?? customer?.email}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {customer?.awardedCoin ?? ""}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {formatDateAndTime(customer?.awardedDate) ?? "N/A"}
                      </StyledTableCell>

                      <StyledTableCell align="center">Full </StyledTableCell>
                    </StyledTableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ textAlign: "center", opacity: 0.5 }}
                  >
                    No Results
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Box
            sx={{
              paddingY: "1rem",
              paddingX: "1.5rem",
              color: "#5E768D",
              fontSize: "12px",
            }}
          >
            <Box
              sx={{
                width: "fit-content",
                border: "1px solid #E5E5E5",
                borderRadius: "64px",
                marginX: "auto",
                overflow: "hidden"
              }}
            >
              <Button
                disabled={page <= 1}
                onClick={() => setPage((prev) => prev - 1)}
                sx={{ paddingX: "1px", marginRight: 0.5, minWidth: "40px" }}
              >
                <ChevronLeft />
              </Button>
              Page {page} of {totalPage}
              <Button
                disabled={page === totalPage || totalPage === 0}
                onClick={() => setPage((prev) => prev + 1)}
                sx={{ paddingX: "1px", marginLeft: 0.5, minWidth: "40px" }}
              >
                <ChevronRight />
              </Button>
            </Box>
          </Box>
        </TableContainer>
      </div>
    </Box>
  );
};

export default AdvertisementReports;
