import React, { useState } from "react";
import "./VerticalCard.scss";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Pagination,
  Paper,
} from "@mui/material";
import { VerticalCardLoader } from "../VerticalCardLoader";
import { restaurantData, restaurantValue } from "../../interfaces";
import decrease from "../../assets/images/decrease.svg";
import grow from "../../assets/images/grow-up.svg";
interface Props extends restaurantData {
  rowsPerPage: number;
}

export default function VerticalCard({
  restaurantsData,
  isLoading,
  rowsPerPage,
}: Props) {
  const [page, setPage] = useState(1);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const totalRows = restaurantsData?.length ?? 0;
  const pageCount = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <>
      {/* <Card
        sx={{
          boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)",
          textAlign: "center",
          minWidth: 300,
          color: "#0A142E",
          minHeight: 220,
          p: 0,
          borderRadius: 0,
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ width: "30%", border: "none" }}
                  align="left"
                ></TableCell>
                <TableCell
                  sx={{
                    width: 450,
                    border: "none",
                    fontWeight: 600,
                    fontSize: 12,
                  }}
                  align="center"
                >
                  # of Orders
                </TableCell>
                <TableCell sx={{ width: "10%", border: "none" }}></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: 450,
                    border: "none",
                    fontWeight: 600,
                    fontSize: 12,
                  }}
                >
                  Fees Collected
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer
          sx={{ maxHeight: 300, boxShadow: "none", borderRadius: 0 }}
          component={Paper}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ backgroundColor: "blue" }}>
              <TableRow sx={{ fontSize: 11 }}>
                <TableCell
                  sx={{
                    width: "30%",
                    background: "white",
                    fontSize: "15px",
                    fontWeight: 600,
                    paddingTop: 0,
                    paddingBottom: 2,
                  }}
                >
                  Restaurants
                </TableCell>
                <TableCell
                  sx={{
                    width: 70,
                    textAlign: "center",
                    background: "white",
                    fontSize: 11,
                    padding: 0,
                  }}
                >
                  Current
                </TableCell>
                <TableCell
                  sx={{
                    width: 70,
                    textAlign: "center",
                    background: "white",
                    fontSize: 11,
                    padding: 0,
                  }}
                >
                  Past
                </TableCell>
                <TableCell
                  sx={{
                    width: 70,
                    textAlign: "center",
                    background: "white",
                    fontSize: 11,
                    padding: 0,
                  }}
                >
                  Trend
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    background: "white",
                    fontSize: 11,
                    padding: 0,
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    width: 70,
                    textAlign: "center",
                    background: "white",
                    fontSize: 11,
                    padding: 0,
                  }}
                >
                  Current
                </TableCell>
                <TableCell
                  sx={{
                    width: 70,
                    textAlign: "center",
                    background: "white",
                    fontSize: 11,
                    padding: 0,
                  }}
                >
                  Past
                </TableCell>
                <TableCell
                  sx={{
                    width: 70,
                    textAlign: "center",
                    background: "white",
                    fontSize: 11,
                    padding: 0,
                  }}
                >
                  Trend
                </TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <VerticalCardLoader />
            ) : (
              <TableBody>
                {restaurantsData !== undefined &&
                restaurantsData?.length > 0 ? (
                  restaurantsData
                    ?.slice(startIndex, endIndex)
                    .map((item: restaurantValue, index: number) => {
                      return (
                        <TableRow className="table-body" key={index}>
                          <TableCell
                            sx={{
                              fontSize: 12,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {item?.restaurantName}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: 70,
                              textAlign: "center",
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {" "}
                            {item?.totalOrder}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: 70,
                              textAlign: "center",
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {" "}
                            {`(${item?.pastTotalOrder})`}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: 70,
                              textAlign: "center",
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Tooltip
                              title={`Trend Order = ${item.trendOrder}`}
                              placement="top-end"
                            >
                              {item.trendOrder < 0 ? (
                                <img alt="" src={decrease} />
                              ) : (
                                <img alt="" src={grow} />
                              )}
                            </Tooltip>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell
                            sx={{
                              width: 70,
                              textAlign: "center",
                              fontSize: 12,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {`$${item?.totalFees?.toFixed(2)}`}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: 70,
                              textAlign: "center",
                              fontSize: 12,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {" "}
                            {`($${item?.pastTotalFees?.toFixed(2)})`}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: 70,
                              textAlign: "center",
                              fontSize: 12,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Tooltip
                              title={`Trend Fees = ${item.trendFees}`}
                              placement="top-end"
                            >
                              {item.trendFees < 0 ? (
                                <img alt="" src={decrease} />
                              ) : (
                                <img alt="" src={grow} />
                              )}
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <></>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        />
      </Card> */}

      <Box sx={{ display: "flex", marginTop: "1.5rem", gap: "1.5rem" }}>
        <TableContainer
          component={Paper}
          className="tavleV1"
          sx={{
            borderRadius: "0.5rem",
            maxHeight: "600px",
            overflow: "auto",
            border: "1px solid #E5E5E5",
            boxShadow: "none",
          }}
        >
          <Box
            sx={{
              paddingY: "1rem",
              paddingX: "1.5rem",
              color: "#5E768D",
              fontSize: "12px",
              background: "white",
              position: "sticky",
              top: 0,
            }}
          >
            Order Details
          </Box>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: "#CD4D4B",
                color: "white",
                position: "sticky",
                top: 50,
              }}
            >
              <TableRow>
                <TableCell>Restaurants</TableCell>
                <TableCell align="center">Current</TableCell>
                <TableCell align="center">Past</TableCell>
                <TableCell align="center">trend</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurantsData !== undefined && restaurantsData?.length > 0 ? (
                restaurantsData
                  ?.slice(startIndex, endIndex)
                  .map((item: restaurantValue, index: number) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        {" "}
                        {item?.restaurantName}
                      </TableCell>
                      <TableCell align="center"> {item?.totalOrder}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {`(${item?.pastTotalOrder})`}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Tooltip
                          title={`Trend Order = ${item.trendOrder}`}
                          placement="top-end"
                        >
                          {item.trendOrder < 0 ? (
                            <img alt="" src={decrease} />
                          ) : (
                            <img alt="" src={grow} />
                          )}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          className="tavleV1"
          sx={{
            borderRadius: "0.5rem",
            maxHeight: "600px",
            overflow: "auto",
            border: "1px solid #E5E5E5",
            boxShadow: "none",
          }}
        >
          <Box
            sx={{
              paddingY: "1rem",
              paddingX: "1.5rem",
              color: "#5E768D",
              fontSize: "12px",
              background: "white",
              position: "sticky",
              top: 0,
            }}
          >
            Fees Collected
          </Box>
          <Table sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: "#CD4D4B",
                color: "white",
                position: "sticky",
                top: 50,
              }}
            >
              <TableRow>
                <TableCell>Restaurants</TableCell>
                <TableCell align="center">Current</TableCell>
                <TableCell align="center">Past</TableCell>
                <TableCell align="center">trend</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurantsData !== undefined && restaurantsData?.length > 0 ? (
                restaurantsData
                  ?.slice(startIndex, endIndex)
                  .map((item: restaurantValue, index: number) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        {" "}
                        {item?.restaurantName}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {`$${item?.totalFees?.toFixed(2)}`}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {`($${item?.pastTotalFees?.toFixed(2)})`}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Tooltip
                          title={`Trend Fees = ${item.trendFees}`}
                          placement="top-end"
                        >
                          {item.trendFees < 0 ? (
                            <img alt="" src={decrease} />
                          ) : (
                            <img alt="" src={grow} />
                          )}
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          paddingTop: "1.5rem",
          paddingX: "1.5rem",
          background: "white",
          position: "sticky",
          bottom: 0,
        }}
      >
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          className="paginationV1"
        />
      </Box>
    </>
  );
}
