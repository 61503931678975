import { CircularProgress, Box } from "@mui/material";
import { Layout } from "../../navigations/Layout";

export const LoadingComponent = () => {
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    </Layout>
  );
};
