import { createSlice } from "@reduxjs/toolkit";
import { OrdersState } from "../../interfaces/orders";

const initialState: OrdersState = {
  data: [],
  selectedData: {}
};


export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrdersData: (state, action) => {
      state.data = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
  },
});

export const { setOrdersData, setSelectedData } =
  ordersSlice.actions;
export default ordersSlice.reducer;