import React, { useState } from "react";
import "./Card.scss";
import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import { basicCardProps } from "../../interfaces";
import { useAppSelector } from "../../redux/store";
import { CardLoader } from "../CardLoader";
import {
  CoinDollarOutline,
  DeviceOutlineComputer,
  DownArrow,
  MobileOutline,
  ShippingDeliveryOutline,
  ShippingDeliveryOutlineReturn,
  TruckOutline,
  UpArrow,
} from "../../assets/icons/iconComponents";
import { AddCircle, InfoOutlined } from "@mui/icons-material";
import Chip from "@mui/material/Chip";

export default function BasicCard({
  title,
  subtitle,
  data,
  isAverage,
  type,
  isWhole,
  isConversion,
  conversionData,
  noArrow,
  ownLogo,
  logo,
  handleCoinsClick,
  showCoinModal,
  handleHistoryListingClick,
  customerTags,
}: basicCardProps) {
  const isLoading = useAppSelector((state) => state.auth.loading);
  const [showTooltip, setShowTooltip] = useState(false);
  const filterData = useAppSelector((state) => state.dashboard.filter);

  return (
    <>
      <div className="cardV1">
        <h3>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {showCoinModal ? (
              <span onClick={handleHistoryListingClick}>{title}</span>
            ) : (
              <span>{title}</span>
            )}

            {showCoinModal && (
              <IconButton
                size="small"
                color="primary"
                onClick={handleCoinsClick}
              >
                <AddCircle />
              </IconButton>
            )}
          </Box>
        </h3>
        {!isLoading ? (
          <>
            <div
              onClick={showCoinModal ? handleHistoryListingClick : undefined}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1.5rem",
                  fontSize: "28px",
                  color: "#CD4D4B",
                  fontWeight: "500",
                }}
              >
                <div className="badge-box-v1">
                  {ownLogo ? logo : <ShippingDeliveryOutline />}
                </div>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    className={`${
                      data && data?.percentage < 0 ? "text-green" : "text-red "
                    }`}
                  >
                    {type === "$" ? (
                      <span   className="cardValue">
                        {type}
                        {isConversion
                          ? conversionData
                          : data && data?.current !== undefined
                          ? data?.current.toLocaleString()
                          : "0"}
                          
                      </span>
                    ) : customerTags ? (
                      <Box
                       className="tagschip"
                        sx={{
                          marginTop: "1rem",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "0.5rem",
                        }}
                      >
                        {customerTags.map((tag, index) => (
                          <Chip key={index} label={tag} disabled />
                        ))}
                      </Box>
                    ) : (
                      <span  className="cardValue">
                        {isConversion
                          ? conversionData
                          : data && data?.current
                          ? isWhole
                            ? Math.floor(data?.current)
                            : data?.current?.toFixed(2)
                          : 0}{" "}
                        {type === "%" && type}
                      </span>
                    )}
                  </span>

                  {noArrow ? (
                    ""
                  ) : (
                    <span className="iconSpan">
                      {data && data?.percentage < 0 ? (
                        <DownArrow />
                      ) : (
                        <UpArrow />
                      )}
                    </span>
                  )}
                </Box>
              </Box>
              {!isConversion && (
                <ul>
                  <li>
                    {data?.percentage ? (
                      <>
                        <span>{data.percentage.toFixed(2)} </span>
                        <span>
                          {data.percentage < 0 ? <DownArrow /> : <UpArrow />}
                        </span>
                        <Tooltip
                          title={`Compared to previous  ${filterData}`}
                          open={showTooltip}
                          onClose={() => setShowTooltip(false)}
                          onOpen={() => setShowTooltip(true)}
                        >
                          <IconButton
                            size="small"
                            onClick={() => setShowTooltip(!showTooltip)}
                          >
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : null}
                  </li>
                </ul>
              )}
            </div>
          </>
        ) : (
          <CardLoader />
        )}
      </div>
    </>
  );
}
