// function getEnvironment() {
//     return {
//         envName: "LOCAL",
//         apiUrl: "http://localhost:4000",
//         pageSize: 20,
//     };
// }

// let appEnv = getEnvironment();

export const BASE_URL = process.env.REACT_APP_API_URL;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
