import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  versionData: {
    androidVersionNumber: 0,
    iosVersionNumber: 0,
    createdAt: "",
    defaultCheck: "",
    updatedAt: "",
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppVersionData: (state, action) => {
      state.versionData = action.payload;
    },
  },
});

export const { setAppVersionData } = appSlice.actions;
export default appSlice.reducer;
