import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  filter: "allTime",
  customFilterData: {
    current: new Date(), // Provide the initial value you want here
    previous: new Date(), // Provide the initial value you want here
  },
  restaurantData: [],
  customerDetails: [],

  customerRestaurantDetails: {
    customerDetails: [],
    restaurantName: "",
    totalConvertedOrders: 0,
    totalConvertedCustomers: 0,
    totalConvertedSpent: 0,
  },
};

export const conversionSlice = createSlice({
  name: "conversion",
  initialState,
  reducers: {
    setConversionData: (state, action) => {
      state.data = action.payload;
    },
    setRestaurantData: (state, action) => {
      state.restaurantData = action.payload;
    },
    setRestaurantCustomerData: (state, action) => {
      state.customerDetails = action.payload;
    },
    setCustomerRestaurantDetails: (state, action) => {
      state.customerRestaurantDetails = action.payload;
    },
    setConversionFilterData: (state, action) => {
      if (action.payload === null) {
        state.filter = "allTime";
      } else {
        state.filter = action.payload;
      }
    },

    setCustomFilterData: (state, action) => {
      state.customFilterData = action.payload;
    },
  },
});

export const {
  setConversionData,
  setConversionFilterData,
  setCustomFilterData,
  setRestaurantData,
  setRestaurantCustomerData,
  setCustomerRestaurantDetails,
} = conversionSlice.actions;
export default conversionSlice.reducer;
